import React,{useEffect, useState} from 'react';
import { Link,useLocation,useNavigate } from 'react-router-dom';
import Nav from './Nav/Nav';
import LoggedNav from './Nav/LoggedNav';
import { useAuthContext } from '../context';
import { selector,dispatch as parentDispatch } from '../redux';
import { getCategoriesThunk } from '../redux/Thunk';
import { getCategoriesPostThunk } from '../redux/Thunk';

const Header = (props) => {
    const userAuth=useAuthContext();
    let categories=selector((state)=>state.categories);
    let location=useLocation();
    let navigate=useNavigate()
    let dispatch=parentDispatch();
    useEffect(()=>{
        dispatch(getCategoriesThunk());
    },[dispatch])
    const onHandler=(categoryId=false,subCatid=false,limit=4)=>{
    dispatch(getCategoriesPostThunk(categoryId,subCatid,limit))
    if(location.pathname !=="/"){
        window.localStorage.setItem("redirect",true);
        navigate("/")
    }
         }
    return (
    <>
    <header>
            <div className={`site-header ${props.headerClass}`}>
                <div className="container">
                    <nav className="navbar navbar-expand-md navbar-light align-items-center">
                        <Link className="navbar-brand" to="/"><img src="assets/images/site-logo.svg" alt="Brand Logo" /></Link>
                        <div className="collapse navbar-collapse" id="siteNavbar">
                            {/* <!-- Explore Categories Dropdown --> */}
                            <div className="dropdown exploreCat">
                                <button className="btn btn-explore dropdown-toggle" type="button" id="exploreCategory" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fas fa-compass"></i> Explore
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="exploreCategory">
                                    {!categories.loading && categories.data.map((val)=>{
                                         return (<>
                                         <li className="sub-dropdown" key={val._id}>
                                        <a href='javascript:void(0)' className="dropdown-item main"  onClick={()=>onHandler(val._id,false,4)}>{val.name} <i
                                            className="fas fa-caret-right"></i></a>
                                        <ul className="submenu">
                                            {val.subCategories.map((val2)=>{
                                             return <li key={val2._id}><a className="dropdown-item"onClick={()=>onHandler(val2.category_id,val2._id,4)}>{val2.name}</a></li>
                                            })}
                                           
                                           
                                        </ul>
                                    </li>
                                         
                                         </>)
                                    })}
                                    
                                   
                                   
                                    
                                </ul>
                            </div>
                           
                            {props.login ? <LoggedNav userData={userAuth.user} /> : <Nav />}
                            
                           </div>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#siteNavbar" aria-controls="siteNavbar" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </nav>
                </div>
            </div>
        </header>
       
    </>);
}

export default React.memo(Header);