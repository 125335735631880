import { toasterFunction } from "../Components/DefaultComponents";
import { useAuthContext } from "../context";
import { Storage } from "../helpers";
export const useUpdateProfile = (data = false, cb = () => {}) => {
    const userAuth = useAuthContext();
    const updateProfile = (data = false, successText = false) => {

        if (data) {
            let obj = {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                username: data.username,
                emailVerified: data.emailVerified,
                profile_pic: data.profile_pic,
                about: data.about,
                website_url: data.website_url,
                cover_pic: data.cover_pic,
                ispassword: data.ispassword,
                userType: data.userType,
                notificationSettings: data.notificationSettings
            }
            Storage.setLocalStorage({
                key: `${process.env.NODE_ENV}-user`,
                value: JSON.stringify(obj)
            });
            obj = {...obj,
                id: data._id,
            }
            // if (!successText) {
            //     toasterFunction.success("Profile has been updated");
            // } else {
            //     toasterFunction.success(successText);

            // }

            userAuth.updateState(obj);
            return data;
        }
    }
    return [updateProfile];
}