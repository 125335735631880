import { useState } from "react"
import { toasterFunction } from "../DefaultComponents"
import { Auth } from "../../sdk"
import { useNavigate } from "react-router-dom"
export const Search = () => {
    const [state,setState]=useState("");
    const navigate=useNavigate();
    const onSubmit=(e)=>{
        e.preventDefault();
        window.localStorage.setItem("search",state);
        navigate("/search")

    }
    return (<>
        <div className="modal fade success_popup" id="searchPopup" tabIndex="-1" aria-labelledby="searchPopupLabel" aria-hidden="true" >
            <div className="modal-dialog">
                <div className="modal-content">
                    <form onSubmit={onSubmit}>
                        <div className="success_content">
                            <div className="modal-header justify-content-center border-0">
                                <h2 className="modal-title" id="searchPopupLabel">Search</h2>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-center py-4">
                                <p className="mt-0 mb-3">Search a person, photo, video or document</p>
                                <input class="form-control" type="text" value={state} placeholder="Search" name="search" onChange={(e)=>{
                                    setState(e.target.value);
                                }}/>
                            </div>
                            <div className="modal-footer justify-content-center border-0">
                                <button type="submit" className="btn site-btn w-100"><span>Search</span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>)
}