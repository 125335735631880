import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const toasterFunction={
    success:(data)=>{
        toast.success(data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId:"toastid_1"
            });
    },
    error:(data)=>{
        toast.error(data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId:"toastid_1"
            });
    }
}
export const ToasterComponent=()=>{
    
    return (<ToastContainer/>);
}