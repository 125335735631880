import React from 'react';
import { useAuthContext } from '../context';
import AddPost from './Addposts/AddPost';
import { Login,Register,VerifyEmail,ResetPassword,TermsAndCondition, ConfirmDeleteAcc, Search } from './popup';
import AddResource from './popup/AddResource';
import Myprofilecta from './Profile/MyProfileCta';
const Popups = () => {
    const userAuth=useAuthContext();
    const showTab=(id)=>{
        window.$(document).ready(function(){
            window.$(id).modal("show");
        })
    }
   
     if(!userAuth.isAuthenticated){
     return (
     <>
        {/* <Register showTab={showTab}/> */}
        <ResetPassword/>
        {/* <Login/> */}
        {/* <TermsAndCondition/> */}
        <Search />
        </>
)
    }
    else{
        return (
        <>
        <VerifyEmail/>
        <TermsAndCondition/>
        <AddPost/>
       
        <AddResource/>
        <Myprofilecta/>
        <ConfirmDeleteAcc />
        <Search />
        </>
        )
}
}
export default Popups;