import { Categories } from "../../sdk";
import { Get_Categories } from "../ActionConstants";
export const getCategoriesThunk=()=>{
    return async (dispatch)=>{
    try{
     let response=await new Categories().getCategories();
     dispatch({type:Get_Categories.success,payload:response})
    }catch(e){
    dispatch({type:Get_Categories.error,payload:e.response})
        }
        }
}