import {  Storage } from "../helpers";
import {axios} from "./config"
export class Follow extends axios{
   axios;
   token;
   prefix;
   constructor(){
   super();
   this.axios=this.axiosConfig();
   this.token=Storage.getCookie(`${process.env.NODE_ENV}-userToken`);
   this.prefix="/follow/"
    }
    async followToggle(data){
    try{
    let result=await this.axios({
        method:"post",
        url:this.prefix.concat("followToggle"),
        data:data,
        headers:{
        Authorization:this.token
        }
        });
        return new Promise((res,rej)=>{
        res(result.data);
        })
    
   
    }catch(e){
    return new Promise((res,rej)=>{
    rej(e);
    })
    }
    }
    async getCollectionByPatternFollowers(name="",userID){
        try{
        let result=await this.axios({
            method:"get",
            url:this.prefix.concat(`getByPatternFollowers/${name}/${userID}`),
            headers:{
            Authorization:this.token
            }
            });
            return new Promise((res,rej)=>{
            res(result.data);
            })
        
       
        }catch(e){
        return new Promise((res,rej)=>{
        rej(e);
        })
        }
    }
    async getCollectionByPatternFollowings(name="",userID){
        try{
        let result=await this.axios({
            method:"get",
            url:this.prefix.concat(`getByPatternFollowings/${name}/${userID}`),
            headers:{
            Authorization:this.token
            }
            });
            return new Promise((res,rej)=>{
            res(result.data);
            })
        
       
        }catch(e){
        return new Promise((res,rej)=>{
        rej(e);
        })
        }
    }
    async followerSuggestion(){
        try{
        let result=await this.axios({
            method:"post",
            url:this.prefix.concat("followerSuggestion"),
            headers:{
            Authorization:this.token
            }
            });
            return new Promise((res,rej)=>{
            res(result.data);
            })
        
       
        }catch(e){
        return new Promise((res,rej)=>{
        rej(e);
        })
        }
    }

            }



