import React from 'react';

const PostDetailControls = (props) => {
    return (<>
        <div className="post-action-share">
            <button className="btn p-0 lh-1 collection-btn">
                <i className="fas fa-share-alt"></i>
                <span>Share</span>
            </button>
        </div>
        <p className='post_time ms-auto d-none d-md-block'>{props.postTime}</p>
    </>);
}

export default PostDetailControls;