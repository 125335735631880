import React,{useEffect,useRef} from 'react';
import LikeBtnContainer from './LikeBtnContainer';
import * as moment from "moment"
const CommentContainer = (props) => {
    const inputReference = useRef(null);
    useEffect(()=>{
        if(props.data._id===props.parentState.state.parentId){
            inputReference.current.focus();
        }
        
    },[])
     
    return (<>
       
        <div className='comment-container'>
       
            <div className="row flex-nowrap">
                <div className="col-auto profile_img">
                    <img src={props.data.user.profile_pic?props.data.user.profile_pic:"assets/images/avatar.jpg"} alt="Profile Img" />
                </div>
                <div className="col-auto comment_content">
                    <div className="profile_name d-flex flex-wrap align-items-center">
                        <h4>{props.data.user.firstName} {props.data.user.lastName}</h4>
                        <p className='post_time'>{moment(props.data.createdAt).fromNow()}</p>
                    </div>
                    <div className="comment_text">
                        <p>{props.data.description}</p>
                    </div>
                    <div className="post-controls d-flex flex-wrap align-items-center">
                        {/* Reply Buttoon */}
                        <div className="post-action-reply">
                            <button className='btn like-btn p-0' onClick={()=>props.onReply(props.data._id)}>
                                <i className="fas fa-reply"></i>
                                Reply
                            </button>
                        </div>
                        {/* Like Buttoon */}
                        
                {/* <div className="post-action-like">
                <button className={`btn like-btn p-0 ${(likesList.includes(userAuth.user.id))?"is-liked":""}`} onClick={()=>{liked(props.post_id)}}>
                <svg width="16" height="14" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.62676 0.846188C9.10518 0.622625 9.62607 0.504586 10.1541 0.500076C10.596 0.497083 11.0341 0.582234 11.4427 0.750548C11.8514 0.918908 12.2224 1.1671 12.534 1.48059C12.8457 1.79408 13.0916 2.16658 13.2576 2.57628C13.4235 2.98599 13.506 3.42468 13.5004 3.86667L13.5003 3.86667V3.87309C13.5003 5.04075 12.9829 6.13786 11.9717 7.38198C10.9601 8.62665 9.50629 9.95741 7.70698 11.6045L7.67892 11.6302L7.67771 11.6313L7.00159 12.2461L6.3231 11.6244L6.32253 11.6239L6.31268 11.6149C4.50452 9.96326 3.04399 8.62912 2.02884 7.38098C1.01776 6.13783 0.500315 5.04075 0.500315 3.87309H0.500356L0.500274 3.86667C0.494599 3.42468 0.577139 2.98599 0.743061 2.57629C0.908984 2.16658 1.15496 1.79408 1.46659 1.48059C1.77822 1.1671 2.14924 0.918908 2.55796 0.750548C2.96656 0.582234 3.40461 0.497084 3.84651 0.500076C4.37456 0.504586 4.89546 0.622626 5.37387 0.846188C5.8524 1.06981 6.27722 1.39377 6.61949 1.79608L7.00032 2.24372L7.38114 1.79608C7.72341 1.39377 8.14823 1.06981 8.62676 0.846188Z" fill="" stroke="" />
                </svg>
                Like
            </button>
        </div> */}
                      
                    </div>
                    {props.data._id===props.parentState.state.parentId &&  
        <div className="write_comment">
        <form onSubmit={(e)=>props.onSubmit(e)} data-id={props.data.post_id}>
        <label htmlFor="post_comment">Write your Comment</label>
                                    <div className="d-flex">
                                        <input type="text" className='form-control' name="reply" ref={inputReference} value={props.parentState.state.replyDescription} onChange={props.onHanlder}  placeholder='Dorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod' />
                                        <button type="submit" className='btn site-btn'><span><img src="assets/images/plain.svg" alt="Paper Plane" /></span></button>
                                    </div>
                                </form>
                            </div>
                            }
                </div>
              
            </div>
        </div>
        
{props.data.replies.map((val,index)=>{
    return(<>
     <div className="comment_reply">
 <CommentContainer
     key={index}
     id={index}
     data={val}
     parentState={{state:props.parentState.state,setState:props.parentState.setState}}
     type="reply"
     onReply={props.onReply}
     onHanlder={props.onHanlder}
     onSubmit={props.onSubmit}
 />
</div>
    </>)

})}
   
     
    </>);
}

export default CommentContainer;