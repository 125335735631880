import React, {useState} from 'react';
import { useAuthContext } from '../../context';

import { Post } from '../../sdk';
import { toasterFunction } from '../DefaultComponents';

const CollectionCard = (props) => {
    const userAuth=useAuthContext();
    console.log(userAuth.user.id)
    const [likeBtnClass, setlikeBtnClass] = useState('');
    let likesList=[]
    
    
    likesList=props.postDetails.likes.map((val)=>{
        return val._id;
    })
    
   
   
    let defaultClass = likeBtnClass;
    const LikeThem = () => {
            if (defaultClass === ''){
                setlikeBtnClass('is-liked');
            } else {
                setlikeBtnClass('');
            }
            
    }
   
    const liked=async(post_id)=>{
        try{
         let result=await new Post().likeToggle({post_id:post_id});
         userAuth.categoryFilter()
         toasterFunction.success(result.message);
        }catch(e){
            
        toasterFunction.error("Error occur , try again");
        }
    
    }
    return (<>
        <div className="card-collection d-flex justify-content-between align-items-center">
            <button className="collection-btn" onClick={(e)=>props.onClickTarget(e,props.postId,`#selectCollection`)}>
                <i className="far fa-plus-circle"></i>
                <span>Add to Collection</span>
            </button>
            <button className={`like-btn ${(likesList.includes(userAuth.user.id))?"is-liked":""}`} onClick={()=>{liked(props.postId)}}>
                <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.62676 0.846188C9.10518 0.622625 9.62607 0.504586 10.1541 0.500076C10.596 0.497083 11.0341 0.582234 11.4427 0.750548C11.8514 0.918908 12.2224 1.1671 12.534 1.48059C12.8457 1.79408 13.0916 2.16658 13.2576 2.57628C13.4235 2.98599 13.506 3.42468 13.5004 3.86667L13.5003 3.86667V3.87309C13.5003 5.04075 12.9829 6.13786 11.9717 7.38198C10.9601 8.62665 9.50629 9.95741 7.70698 11.6045L7.67892 11.6302L7.67771 11.6313L7.00159 12.2461L6.3231 11.6244L6.32253 11.6239L6.31268 11.6149C4.50452 9.96326 3.04399 8.62912 2.02884 7.38098C1.01776 6.13783 0.500315 5.04075 0.500315 3.87309H0.500356L0.500274 3.86667C0.494599 3.42468 0.577139 2.98599 0.743061 2.57629C0.908984 2.16658 1.15496 1.79408 1.46659 1.48059C1.77822 1.1671 2.14924 0.918908 2.55796 0.750548C2.96656 0.582234 3.40461 0.497084 3.84651 0.500076C4.37456 0.504586 4.89546 0.622626 5.37387 0.846188C5.8524 1.06981 6.27722 1.39377 6.61949 1.79608L7.00032 2.24372L7.38114 1.79608C7.72341 1.39377 8.14823 1.06981 8.62676 0.846188Z" fill="" stroke="" />
                </svg>
                Liked
            </button>
        </div>
        
    </>);
    
}

export default CollectionCard;
