import { Storage } from "../helpers";
import { axios } from "./config"
export class Post extends axios {
    axios;
    token;
    prefix;
    constructor() {
        super();
        this.axios = this.axiosConfig();
        this.token = Storage.getCookie(`${process.env.NODE_ENV}-userToken`);
        this.prefix = "/post/"
    }
    async uploadMedia(media, type = "image") {
        let formData = new FormData();
        for (let file of media) {
            formData.append(`${type}[]`, file);
        }


        let url = (type === "image") ? this.prefix.concat("uploadImage") : this.prefix.concat("uploadVideo");
        try {
            let result = await this.axios({
                method: "post",
                url: url,
                data: formData,
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })


        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async uploadFiles(media) {
        let formData = new FormData();
        for (let file of media) {
            formData.append(`files[]`, file);
        }

        let url = this.prefix.concat("uploadFiles");
        try {
            let result = await this.axios({
                method: "post",
                url: url,
                data: formData,
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })


        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }

    async mediaFromUrl(data) {
        try {
            let result = await this.axios({
                method: "post",
                url: this.prefix.concat("imageFromUrl"),
                data: data,
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })


        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }

    async followToggle(data) {
        try {
            let result = await this.axios({
                method: "post",
                url: this.prefix.concat("followToggle"),
                data: data,
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })


        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async createPost(data) {
        try {
            let result = await this.axios({
                method: "post",
                url: this.prefix.concat(""),
                data: data,
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })


        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async editPost(data) {
        try {
            let result = await this.axios({
                method: "put",
                url: this.prefix.concat(""),
                data: data,
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })


        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async deletePost(id) {
        try {
            let result = await this.axios({
                method: "delete",
                url: this.prefix.concat(""),
                data: {id},
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })


        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async getUserInterstPost() {
        try {
            let result = await this.axios({
                method: "post",
                url: this.prefix.concat("postByIntersts"),
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })
        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async getCategoryPost(data) {
        try {
            let result = await this.axios({
                method: "post",
                url: this.prefix.concat("postByCategories"),
                data: data,
            });
            return new Promise((res, rej) => {
                res(result.data);
            })
        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async likeToggle(data) {
        try {
            let result = await this.axios({
                method: "post",
                url: this.prefix.concat("likeToggle"),
                data: data,
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })
        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async postDetails(id) {
        try {
            let result = await this.axios({
                method: "get",
                url: this.prefix.concat(`postDetails/${id}`),
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })


        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async addToCollectionOrResource(data) {
        try {
            let result = await this.axios({
                method: "post",
                url: this.prefix.concat("addToCollectionOrResource"),
                data: data,
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })


        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async getFollowersPost() {
        try {
            let result = await this.axios({
                method: "post",
                url: this.prefix.concat("follwersPost"),
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })
        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async addComment(data) {
        try {
            let result = await this.axios({
                method: "post",
                url: this.prefix.concat("addComment"),
                data: data,
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })


        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async searchInSite(data) {
        try {
            let result = await this.axios({
                method: "post",
                url: this.prefix.concat("searchPostByKeyword"),
                data: data,
               });
            return new Promise((res, rej) => {
                res(result.data);
            })


        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }

}