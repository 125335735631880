import React, { useState, useEffect } from 'react';
import {  useParams } from 'react-router-dom';
import { useAuthContext } from '../context';
import { Loader } from './DefaultComponents';
import CollectionMain from './Profile/CollectionMain';
import UserResource from './AllPosts/UserResource';
import { Resource } from '../sdk';
import axios from 'axios';



const CollectionResourceDetail = () => {
    
    const onClick = (url, type) => {
        axios(url, {
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }).catch((e) => { })
    }
    const userAuth = useAuthContext();
    const [state, setState] = useState({
        loader: true,
    });
    let userDetails = userAuth.user;
    const param = useParams();
    let id = false;
    let parentId=false;
    if (param.id) {
        id = param.id
    }
    if(param.parentId){
        parentId=param.parentId
    }
   
    useEffect(() => {
        new Resource().getResourceItem(id).then((data) => {
            setState((pre) => {
                return { ...pre, ...data[0], loader: false }
            })
        }).catch((e) => {
            alert("error")
        })
    }, [id]);
    console.log(state);
    return (<>
        {state.loader && <Loader />}
        {(!state.loader) && <main className='profile_view resource_detail_view'>
            {/* Profile Cover Photo */}


            <section className={`profile_cover ${(state.resourceType === 'pdf') || (state.resourceType === 'word') ? 'doc_cover' : null}`}>
                <img src='assets/images/pdf.png' alt="Cover Img" className='main' style={{ cursor: 'pointer' }} onClick={() => onClick(state.media[0], "pdf")} />
            </section>


            {/* Profile Img */}


            {/* Profile Description */}
            <div className="profile_description">
                <div className="container">
                    <h2>{state.title}</h2>
                    <p>{state.description}</p>
                    <div className="post-categories">
                        <div className="row">
                            {state.tags.map((val2) => {
                                return (<>
                                    <div className="col-auto">
                                        <div className="filter_item">
                                            <input type="checkbox" checked={true} name={val2._id} readOnly />
                                            <label htmlFor={val2._id}>
                                                <img src="assets/images/check_mark.svg" alt="Check Mark" />
                                                <span>
                                                    {val2.name}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
            {/* Profile Nav Tabs */}
            <div className="tab-content" style={{paddingTop : 30}} id="nav-tabContent">
                <div className={`tab-pane container fade show active`} id="nav-collection" role="tabpanel" aria-labelledby="nav-collection-tab">
                    <div className="profile_tab_content">
                        {/* Collection Cards */}
                        <div className="collection_container">
                            <h2 className='resourceFrom'>This Resource is from</h2>
                            <div className="detail_page">
                                <CollectionMain data={Array(1).fill(state.resources.find(val=>val._id ===parentId))} type="resources" description={true} parentState={setState} />
                            </div>
                        </div>
                    </div>
                    <div className="row other_resources">
                        <div className="col-12">
                            <h2 className='resourceFrom'>Show other resources in the library</h2>
                        </div>
                        <CollectionMain data={state.resources.find(val=>val._id ===parentId).items.filter(val=>val._id !==id)} type="resourcesItems" parentState={setState} />
                    </div>
                </div>
            </div>
        </main>}
    </>);
}

export default CollectionResourceDetail;
