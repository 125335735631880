import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
const PostSelekton=({ children })=>{
    return (<>
    <Skeleton count={1} width={100}  style={{borderRadius:"25px",}}/>
     <div style={{marginBottom:"16px" }}>
                     <div className={`category-card `}>
                         <div className="thumb">
                           
                            
                           
                           
                         </div>
                       
                         
                     </div>
                     {children}
                 </div>
    </>)
}
export const CategoryPostSkeleton=(props)=>{
return (<>

<Skeleton count={props.count} wrapper={PostSelekton}  style={{height:"100px"}}/>



</>)
}
