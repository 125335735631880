import { useEffect, useState } from "react";
import { toasterFunction } from './DefaultComponents';
import { User } from '../sdk';
import { useQuery, useUpdateProfile  } from '../customHooks';
import { Loader } from './DefaultComponents';
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../context";
const ChangeEmail=()=>{
    let navigate = useNavigate();
    const [updateProfile]=useUpdateProfile();
    const param=useQuery();
    let token=param.get("token");
    let userId=param.get("userId");
    const userAuth=useAuthContext();
    const [state,setState]=useState({
        loader:true
    })
    useEffect(async ()=>{
        if(userAuth.isAuthenticated){
            if(token && userId ){
                try{
                    let result=await new User().changeEmailVerify({token:token,userId:userId});
                    updateProfile(result);
                    toasterFunction.success("email has been changed");
                    navigate("/")
                   setState((pre)=>{
                       return{...pre,loader:false}
                   })
                }catch(e){
                   toasterFunction.error(e.response.data[0])
                   navigate("/")
                   setState((pre)=>{
                    return{...pre,loader:false}
                })
                }
                }
                else{
                    navigate("/")
                }
        }else{
            toasterFunction.error("Please Logged in with relevant account");
        }
       
    },[token,userId])
    
    if(state.loader){
        return (<>
            <Loader/>
            </>)
    }
   else{
       return (<>
       
       </>)
   }
       

}
export default ChangeEmail;


