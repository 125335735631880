import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useAuthentication } from '../../customHooks';
import { Auth } from '../../sdk';
import { toasterFunction } from './Toaster';
export const FaceBookSocialLogin=(props)=>{
    const [afterAuthentication]=useAuthentication();
    const responseFacebook=async ({accessToken})=>{
        try{
              
         let result=await new Auth().socialLogin({
             userType:props.userType,
             logintype:"facebook",
             token:accessToken,
         });
        afterAuthentication(result);
        }catch(e){
            toasterFunction.error("error occur ! Please Try again");
           
        }
    }
    const failFaceBookResponse=()=>{
        toasterFunction.error("error occur ! Please Try again");
    }
    return (
    <>
    <FacebookLogin
    appId={process.env.REACT_APP_FACEBOOKID}
    autoLoad={false}
    fields="name,email,picture"
    callback={responseFacebook}
    onFailure={failFaceBookResponse}
    render={renderProps => (
    <a href="/#" className="btn site-btn fb" onClick={renderProps.onClick}  disabled={renderProps.disabled}><span><i className="fab fa-facebook-f"></i> {props.name}</span></a>
        )}
  />
    
    </>)
}