import React,{useState} from 'react';
import { Link } from 'react-router-dom';

const Nav = () => {
    return (<>
        <ul className="logout navbar-nav ms-auto align-items-md-center">
            <li className="nav-item">
                <button className="btn nav-link search shadow-none" data-bs-toggle="modal" data-bs-target="#searchPopup"><i className="far fa-search"></i></button>
            </li>
            <li className="nav-item">
                <Link className="btn nav-link text-start login" to="/login"><i className="fas fa-lock-alt"></i> Login</Link>
            </li>
            <li className="nav-item">
                <Link to="/join-community" className="btn site-btn join"><span>Join Community</span></Link>
            </li>
        </ul>
    </>);
}

export default Nav;