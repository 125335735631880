export class dataValidation{
    key;
    value;
    submit;
    constructor(key,value,submit){
        this.value=value;
        this.key=key;
        this.submit=submit;
    }
    validate(){
        let validity=false;
        switch(this.key){
            case "email":
            const emailRegex=new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            validity= emailRegex.test(this.value) || (!this.submit && !this.value);
            break;
            case "password":
            const strongRegex = new RegExp(
            '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,64})'
            );
            validity= strongRegex.test(this.value) || (!this.submit && !this.value);
            break;
            default:
            if((String(this.value).length > 1) || (!this.submit && !this.value)){
            validity=true;
            }
            else{
            validity=false;
            }
            break;
        }
        return validity;
    }
}