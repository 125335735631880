import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import App from './App';
// import Header from './Components/Header';
import "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";
import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from './context';
import { Provider } from 'react-redux'
import { store } from './redux';

ReactDOM.render(
  <React.StrictMode>
     <Provider store={store}>
    <BrowserRouter>
     <AuthContextProvider>
      <App />
     </AuthContextProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
