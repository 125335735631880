import React from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context';
import { Auth } from '../../sdk/auth';
import { useAuthentication } from './../../customHooks';
import * as moment from "moment"
import { Notification } from '../../sdk';
import { Encryption } from '../../helpers';

const LoggedNav = ({userData}) => {
    const[,logoutUser]=useAuthentication();
    const userAuth=useAuthContext();
    
    const logOut=async()=>{
        try{
            await new Auth().logout();
            logoutUser();
        }catch(e){
    }
        
    }
    const navigate=useNavigate();
    let notificationCount=(userAuth.notification.filter(val=>val.viewStatus==0)).length;
    const markAsReadAll=async()=>{
    try{
      await new Notification().readAllNotification();
      userAuth.getAllNotification();
}catch(e){
    userAuth.getAllNotification();
    } 
    }
    const readNotfication=async(id,type,rid)=>{
        try{
          await new Notification().readNotification(id);
          userAuth.getAllNotification();
          if(type==="user"){
           navigate(`/profile/${Encryption.encryptBase64(rid)}`);
          }
          else{
            navigate(`/postdetails/${rid}`);
          }
    }catch(e){
        userAuth.getAllNotification();
        } 
        }
   
    return (<>
        <ul className="loggedin navbar-nav w-100 justify-content-between align-items-md-center">
            <div className='left d-flex align-items-md-center w-auto'>
                <li className="nav-item">
                    <NavLink Link to='/home' className='nav-link'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17"><path d="M8,17V11h4v6h5V9h3L10,0,0,9H3v8Z" fill="" /></svg>
                        <span className='label'>Home</span>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to='/company' className='nav-link'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18.74" height="16.865" viewBox="0 0 18.74 16.865"><path d="M766.37,531.748V528H757v16.865h18.74V531.748Zm-5.622,11.243h-1.873v-1.873h1.873Zm0-3.748h-1.873V537.37h1.873Zm0-3.748h-1.873v-1.873h1.873Zm0-3.748h-1.873v-1.873h1.873Zm3.748,11.243h-1.873v-1.873H764.5Zm0-3.748h-1.873V537.37H764.5Zm0-3.748h-1.873v-1.873H764.5Zm0-3.748h-1.873v-1.873H764.5Zm9.37,11.243h-7.5v-1.873h1.873v-1.874H766.37V537.37h1.873V535.5H766.37v-1.873h7.5Zm-1.875-7.5h-1.873v1.875h1.873Zm0,3.748h-1.873v1.874h1.873Z" transform="translate(-757 -528)" fill="" /></svg>
                        <span className='label'>Company</span>
                    </NavLink>
                </li>
            </div>
            <div className='right d-flex align-items-md-center w-auto'>
                <li className="nav-item">
                    <button className="btn nav-link search shadow-none" data-bs-toggle="modal" data-bs-target="#searchPopup"><i className="far fa-search"></i></button>
                </li>
                <li className="nav-item">
                    <button className="btn nav-link chat">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                            <path d="M0,20v0L.01,2A2,2,0,0,1,2,0H18a2,2,0,0,1,2,2V14a2,2,0,0,1-2,2H4L0,20ZM4,10v2h8V10ZM4,7V9H16V7ZM4,4V6H16V4Z" fill="" />
                        </svg>
                    </button>
                </li>
                <li className="nav-item dropdown noti_dropdown">
                    <button className="btn nav-link bellIcon dropdown-toggle shadow-none position-relative" type='button' id="notificationDrop" data-bs-toggle="dropdown" aria-expanded="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="21" viewBox="0 0 18 21"><path d="M9,21a1.992,1.992,0,0,1-1.99-1.99h3.98A1.992,1.992,0,0,1,9,21Zm9-3H0V16.94l2.12-2.12V9A6.85,6.85,0,0,1,7.41,2.31V1.59a1.59,1.59,0,0,1,3.181,0v.72A6.851,6.851,0,0,1,15.88,9v5.82L18,16.94V18Z" fill="" /></svg>
                    {notificationCount > 0 && <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
    {(notificationCount > 9)?"9+":notificationCount}
    
  </span> }
                   
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="notificationDrop">
                        <span className="caret"><img src="assets/images/caret.svg" alt="Caret" /></span>
                        <li>
                            <div className='head d-flex justify-content-between align-items-center'>
                                <p>Notifications</p>
                                <span className='d-flex align-items-center'>
                                    <button type='button' className='btn p-0 markRead' onClick={markAsReadAll}>Mark all as Read</button>
                                    <span className='divider'></span>
                                    <button type='button' className='btn p-0 settngs' onClick={()=>{
                                        navigate("/settings/notificationSettings");
                                    }}>Settings</button>
                                </span>
                            </div>
                        </li>
                        {userAuth.notification.map((val)=>{
                            if(val.notificationType==="likespost"){
                                return (<>
                             <li className={`${(!val.viewStatus) && "unread"}`}>
                            <a className="dropdown-item d-flex align-items-center" onClick={()=>{readNotfication(val._id,"post",val.reletiveId)}}>
                                <div className='profile_img col-auto'>
                                    <img src={val.userBy && val.userBy.profile_pic?val.userBy.profile_pic:'assets/images/user_profile_img.png'} alt='User Profile Img' className='main' />
                                    <img src='assets/images/action_icon_heart.svg' alt='Action Icon Img' className='icon position-absolute' />
                                </div>
                                <div className='text col-auto'>
                                    <p dangerouslySetInnerHTML={{__html: val.description}}>
                                        
                                    </p>
                                    <div className='noti_time'>
                                    {moment(val.createdAt).fromNow()}
                                    </div>
                                </div>
                            </a>
                        </li>
                                    </>)
                            }
                           else if(val.notificationType==="user"){
                                return (<>
                             <li className={`${(!val.viewStatus) && "unread"}`}>
                             <a className="dropdown-item d-flex align-items-center" onClick={()=>{readNotfication(val._id,"user",val.reletiveId)}}>
                                <div className='profile_img col-auto'>
                                <img src={val.userBy && val.userBy.profile_pic?val.userBy.profile_pic:'assets/images/user_profile_img.png'} alt='User Profile Img' className='main' />
                                    <img src='assets/images/action_icon_follow.svg' alt='Action Icon Img' className='icon position-absolute' />
                                </div>
                                <div className='text col-auto'>
                                    <p dangerouslySetInnerHTML={{__html: val.description}}>
                                        
                                    </p>
                                    <div className='noti_time'>
                                    {moment(val.createdAt).fromNow()}
                                    </div>
                                </div>
                            </a>
                        </li>
                                    </>)
                            }
                            else{
                                return (<>
                             <li className={`${(!val.viewStatus) && "unread"}`}>
                             <a className="dropdown-item d-flex align-items-center" onClick={()=>{readNotfication(val._id,"post",val.reletiveId)}}>
                                <div className='profile_img col-auto'>
                                <img src={val.userBy && val.userBy.profile_pic?val.userBy.profile_pic:'assets/images/user_profile_img.png'} alt='User Profile Img' className='main' />
                                    <img src='assets/images/action_icon_save.svg' alt='Action Icon Img' className='icon position-absolute' />
                                </div>
                                <div className='text col-auto'>
                                    <p dangerouslySetInnerHTML={{__html: val.description}}>
                                        
                                    </p>
                                    <div className='noti_time'>
                                    {moment(val.createdAt).fromNow()}
                                    </div>
                                </div>
                            </a>
                        </li>
                                    </>)
                            }
                           
                        })}
                       
                       
                       
                 
                    </ul>
                </li>
                <li className="nav-item add_post">
                    <button className="btn site-btn" type='button' data-bs-toggle="modal" data-bs-target="#addPostPopup"><span><i className="fas fa-plus-circle me-2"></i> Add Post</span></button>
                </li>
                <li className="nav-item dropdown">
                    <button className="btn nav-link dropdown-toggle profil_drop shadow-none" id="profileDrop" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src={(userData.profile_pic)?userData.profile_pic:'assets/images/profile_silhotte.svg'} alt='Profile Sample' />
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="profileDrop">
                        <li><Link className="dropdown-item" to="/settings">Settings</Link></li>
                        <li><Link className="dropdown-item" to="/user-profile">My Profile</Link></li>
                        <li><Link className="dropdown-item" to="/contact">Contact Us</Link></li>
                        <li><Link className="dropdown-item" to="/#" onClick={logOut}>Logout</Link></li>
                    </ul>
                </li>
            </div>
        </ul>
    </>);
}

export default LoggedNav;