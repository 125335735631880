import React, { useState } from 'react';
import { dataValidation, Validate, Modal } from './../../helpers';
import Recaptcha from 'react-google-recaptcha';
import { Auth } from "./../../sdk"
import { useAuthentication } from './../../customHooks';
import { FaceBookSocialLogin, GoogleSocialLogin } from '../DefaultComponents';
import { useNavigate } from 'react-router-dom';
export const Login = () => {
    let navigate = useNavigate();
    const [afterAuthentication] = useAuthentication();
    let [state, setState] = useState({
        userData: {
            email: "",
            password: "",
            recaptcha: false,
            userType: "company",


        },
        errors: {
            email: [],
            password: [],
            recaptcha: [],
            other: [],
            userType: [],

        },
        validity: {
            email: "",
            password: "",
            recaptcha: "",

        },
        buttonDisable: true,
        submit: false,
        loader: false,
        passwordType: "password",
    });
    const onSubmit = async (event) => {
        event.preventDefault();
        setState((pre) => {
            return { ...pre, loader: true }
        })
        try {
            let valid = await Validate.login.validate(state.userData, { abortEarly: false });
            let result = await new Auth().login(valid);
            afterAuthentication(result);
            navigate("/")
            // Modal.hideTab(".modal");
            // Modal.showTab("#successPopup");

        } catch (e) {
            console.log(e);
            let obj = {
                email: [],
                password: [],
                recaptcha: [],
                termsAndCondidtionCheck: [],
                other: []
            }
            let validity = {
                email: "",
                password: "",
                recaptcha: "",
                termsAndCondidtionCheck: "",
            }

            if (e.errors && e.errors.length > 0) {
                for (let error of e.errors) {
                    if (typeof (error) === "string") {
                        obj["password"].push(error);
                        validity["password"] = "error-class";
                    }
                    else {
                        obj[Object.keys(error)[0]].push(Object.values(error)[0]);
                        validity[[Object.keys(error)[0]]] = "error-class";
                    }

                }

            }
            else if (e.response) {
                let keys = Object.keys(e.response.data);
                obj[keys[0]] = e.response.data[keys[0]]
                validity[keys[0]] = "error-class";
            }
            else {
                obj.other.push("Error Occur! Please Contact your Adminstration");

            }
            setState((pre) => {
                return { ...pre, errors: obj, validity: validity, submit: true, loader: false }
            })

        }
    }
    const onHandler = (event) => {
        let key = event.target.name;
        let value = event.target.value;
        let newData = { ...state.userData, [key]: value };
        let oldValidity = state.validity;
        // let newValidity = (new dataValidation(key, value, state.submit)).validate() ? { ...oldValidity, [key]: "" } : { ...oldValidity, [key]: "error-class" };
        setState((pre) => {
            return { ...pre, userData: newData };
        })
    }
    const reCaptchaVerify = (data) => {
        let oldState = state;
        oldState.userData.recaptcha = true;
        oldState.buttonDisable = false;
        setState((pre) => {
            return { ...pre, oldState }
        })
    }
    return (<>
        <div className="modal fade join_form_popup show" style={{ display: "block" }} id="loginForm" tabIndex="-1" aria-labelledby="loginFormLabel" aria-modal="true" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <form onSubmit={onSubmit}>
                    <div className="container">
                        <div className="row justify-content-end">
                            <div className="col-lg-6">
                                <div className="joinus_form login_form">
                                    <div className="modal-header justify-content-center border-0 px-0 pb-0">
                                        <h2 className="modal-title" id="loginFormLabel">Login</h2>
                                        {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                    </div>
                                    <div className="modal-body px-0 pt-0">
                                        {/* <div className="join-as d-flex justify-content-center">

                                            <div className="form-check me-md-3">
                                                <input className="form-check-input" type="radio" name="joinAs" id="inlineRadio1" value="company" defaultChecked={state.userData.userType === "company" ? true : false} required />
                                                <label className="form-check-label" htmlFor="inlineRadio1">As a Company</label>
                                            </div>
                                            <div className="form-check ms-md-3">
                                                <input className="form-check-input" type="radio" name="joinAs" id="inlineRadio2" value="user" defaultChecked={state.userData.userType === "user" ? true : false} required />
                                                <label className="form-check-label" htmlFor="inlineRadio2">As a User</label>
                                            </div>
                                        </div> */}
                                        {/* <!-- Sign Up With Social Accounts --> */}
                                        <div className="signup-social row justify-content-between">
                                            {state.errors.other.map((val, index) => {
                                                return (<>
                                                    <p className='form_error'>{val}</p>
                                                </>)
                                            })}
                                            <div className="col-md-6">
                                                <FaceBookSocialLogin userType={{ "userType": state.userData.userType }} name="Sign In With Facebook" />
                                            </div>
                                            <div className="col-md-6">
                                                <GoogleSocialLogin userType={{ "userType": state.userData.userType }} name="Sign In With Google" />
                                            </div>
                                        </div>
                                        {/* <!-- OR --> */}
                                        <p className="or">OR</p>
                                        <hr />
                                        <div className="with-email">
                                            <h3>Login with Email</h3>
                                            <div className="mb-4">
                                                <label htmlFor="loginEmail" className="form-label">Email address</label>
                                                <input type="email" className={`form-control ${state.validity.email}`} id="loginEmail" name="email" onChange={onHandler} value={state.userData.email} placeholder="sophíabelli@websitename.com" required />
                                                {state.errors.email.map((val, index) => {
                                                    return (<>
                                                        <p className='form_error'>{val}</p>
                                                    </>)
                                                })}
                                            </div>
                                            <div className="mb-4 password position-relative">
                                                <label htmlFor="loginPwd" className="form-label">Password</label>
                                                <div className="position-relative">
                                                    <input type={state.passwordType} className={`form-control`} id="loginPwd" name="password" onChange={onHandler} value={state.userData.password} placeholder="Enter Password" required />

                                                    <button type="button" className="btn p-0 pwd-btn"><i className="fas fa-eye-slash" onClick={() => {
                                                        if (state.passwordType === "password") {
                                                            setState((pre) => {
                                                                return { ...pre, passwordType: "text" }
                                                            })
                                                        }
                                                        else {
                                                            setState((pre) => {
                                                                return { ...pre, passwordType: "password" }
                                                            })
                                                        }
                                                    }}></i></button>
                                                </div>
                                                {state.errors.password.map((val, index) => {
                                                    return (<>
                                                        <p className='form_error'>{val}</p>
                                                    </>)
                                                })}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer align-items-center flex-wrap border-0 px-0">
                                        {/* <!-- Recaptcha --> */}
                                        <div className="col-md-6 recaptcha d-flex justify-content-between align-items-center">
                                            <Recaptcha

                                                elementid="g-recaptcha"
                                                render="onload"
                                                theme='light'
                                                type='image'
                                                sitekey={process.env.REACT_APP_reCAPTCHA_SITE_KEY}
                                                onChange={reCaptchaVerify}
                                                size="normal"

                                            />
                                            {state.errors.recaptcha.map((val, index) => {
                                                return (<>
                                                    <p className='form_error'>{val}</p>
                                                </>)
                                            })}
                                        </div>
                                        {/* <!-- Continue Btn --> */}
                                        <div className="col-md-6">
                                            <button type="submit" className="btn site-btn" disabled={state.buttonDisable}><span>Continue {(state.loader) ? <div className="spinner-border" style={{ width: "1rem", height: "1rem" }}></div> : ""}</span></button>
                                        </div>
                                        <div className="col-12 termsCheck d-flex justify-content-center">
                                            <a type="button" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#forgotPwd">Forgot Password</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>)
}