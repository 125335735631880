import React from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { Modal } from '../../helpers';
import { selector } from '../../redux';
const PostFields = (props) => {
    const ownUser=selector(state=>state.ownUser);
    const getUserCategories=()=>{
      let userIntersts=ownUser.data.intersts;
      let arr=[];
      let userCategories=[];
      if(userIntersts.length){
          for(let value of userIntersts){
            if(!arr.includes(value.category_id)){
                     arr.push(value.category_id);
              }
          }
      }
      if(props.data.state.categories.length){
          for(const value of props.data.state.categories){
              if(arr.includes(value._id)){
                  userCategories.push(value);
              }
          }
      }
    return userCategories;
    }
    let userCategories=getUserCategories();
  
    
    return (<>
        {/* Text Input Fields */}
        <div className="post-field-title">
            <label htmlFor="title">Title</label>
            <input type="text" name="title" value={props.data.state.formData.title} onChange={props.data.onHandler} className='form-control' placeholder='Any Text Here' />
            {props.errors.title.map((val,index)=>{
                                             return (<>
                                             <p className='form_error'>{val}</p> 
                                             </>)
                                           })}

        </div>
        {/* Text Input Category */}
        <div className="post-field-category">
            <label htmlFor="postCategory">Category</label>
            <Multiselect
            displayValue="name"
            customCloseIcon={<><i className='fal fa-times'></i></>}
            onKeyPressFn={function noRefCheck() { }}
            onRemove={function noRefCheck(value,selectItem) { 
                props.data.state.formData.categories=props.data.state.formData.categories.filter((val)=>{
                    return val !==selectItem._id;
                })
                let getTags=ownUser.data.intersts.filter((val)=>{
                    return props.data.state.formData.categories.includes(val.category_id);
                })
                props.data.parentStateFun((pre)=>{
                    return {...pre,...props.data.state,tags:getTags}
                });
            }}
            onSearch={function noRefCheck() { }}
            onSelect={function noRefCheck(value,selectItem) {
              props.data.state.formData.categories.push(selectItem._id);
              let getTags=ownUser.data.intersts.filter((val)=>{
                  return props.data.state.formData.categories.includes(val.category_id);
              })
             
              
              props.data.parentStateFun((pre)=>{
                  return {...pre,...props.data.state,tags:getTags}
              });
            }}
            placeholder="Select Category"
            options={userCategories}
            showCheckbox
            avoidHighlightFirstOption={true}
        />
        {props.errors.categories.map((val,index)=>{
                                             return (<>
                                             <p className='form_error'>{val}</p> 
                                             </>)
                                           })}
        </div>
        {/* Text Input description */}
        <div className="post-field-description">
            <label htmlFor="postDescription">Description</label>
            <textarea  className='form-control' name="description" value={props.data.state.formData.description} onChange={props.data.onHandler} rows={9} placeholder='Dorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua pt enim ad minim'>{props.data.state.formData.description}</textarea>
            {props.errors.description.map((val,index)=>{
                                             return (<>
                                             <p className='form_error'>{val}</p> 
                                             </>)
                                           })}
        </div>
        {/* Text Input Category */}
        <div className="post-field-tags">
            <label htmlFor="postTags">Tags</label>
            <Multiselect
            displayValue="name"
            customCloseIcon={<><i className='fal fa-times'></i></>}
            onKeyPressFn={function noRefCheck() { }}
            onRemove={function noRefCheck(value,selectItem) { 
                props.data.state.formData.tags=props.data.state.formData.tags.filter((val)=>{
                    return val !==selectItem._id;
                })
               
                props.data.parentStateFun((pre)=>{
                    return {...pre,...props.data.state}
                });
            }}
            onSearch={function noRefCheck() { }}
            onSelect={function noRefCheck(value,selectItem) {
                props.data.state.formData.tags.push(selectItem._id);
                props.data.parentStateFun((pre)=>{
                    return {...pre,...props.data.state}
                });
             }}
            placeholder={"select Tags"}
            options={props.data.state.tags}
            showCheckbox
            avoidHighlightFirstOption={true}
        />
        {props.errors.tags.map((val,index)=>{
                                             return (<>
                                             <p className='form_error'>{val}</p> 
                                             </>)
                                           })}
        </div>
        {/* Toggle Buttons */}
        <div className="post-field-toggleBtn">
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch" name="visibiltyMode" defaultChecked={(props.data.state.formData.visibiltyMode==="public")?true:false} onChange={props.data.onHandler}/>
                <label className="form-check-label" htmlFor="publicToggle">Public</label>
            </div>
          
        </div>
        {/* Resources */}
         <div className="post-field-collect row align-items-end">
            <div className="col-md-9">
                <label htmlhtmlFor="postCollections">Resource</label>
                <Multiselect
            displayValue="name"
            customCloseIcon={<><i className='fal fa-times'></i></>}
            onKeyPressFn={function noRefCheck() { }}
            onRemove={function noRefCheck(value,selectItem) { 
                props.data.state.formData.resources=props.data.state.formData.resources.filter((val)=>{
                    return val !==selectItem._id;
                })
                props.data.parentStateFun((pre)=>{
                    return {...pre,...props.data.state}
                });
            }}
            onSearch={function noRefCheck() { }}
            onSelect={function noRefCheck(value,selectItem) {
                props.data.state.formData.resources.push(selectItem._id);
                props.data.parentStateFun((pre)=>{
                    return {...pre,...props.data.state}
                });
             }}
            placeholder={"select Resources"}
            options={ownUser.data.resources}
            showCheckbox
            avoidHighlightFirstOption={true}
        />
                {props.errors.resources.map((val,index)=>{
                                             return (<>
                                             <p className='form_error'>{val}</p> 
                                             </>)
                                           })}
            </div>
            <div className="col-md-3">
                <button className='btn site-btn' type='button'  id="#createResource"  onClick={(e,id="#createResource")=>{
                  Modal.hideTab(".modal");
                  Modal.showTab(id);
                }}><span>Create New Resources</span></button>
            </div>
        </div>
        
        
       
    </>);
}

export default PostFields;
