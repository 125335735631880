import React from 'react';
import BannerSection from '../Filters/BannerSection';
import Footer from '../Footer';
import Header from '../Header';
import CategoryContainer from './CategoryContainer';


const CategoryPage = () => {
    return (<>
  
        {/* <!-- Banner Section --> */}
        <BannerSection
            Title='Business'
            subTitle='To Become The Best Version Of Themselves'
        />
        {/* <!-- Categories Section --> */}
        <section className="categories">
            <div className="container">
                
                <CategoryContainer
                    categoryTitle='Business'
                    categoryLink='/categories'
                />
              
            </div>
        </section>
    </>);
}

export default CategoryPage;