import * as yup from "yup"
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
export const Validate={
    register:yup.object().shape({
        firstName: yup.string().required({firstName:"First Name is required"}).min(2,{firstName:"First Name atleast 2 alphabet"}),
        lastName: yup.string().required({lastName:"Last Name is required"}).min(2,{lastName:"Last Name atleast 2 alphabet"}),
        email: yup.string().email({email:"email should be email format"}).required({email:"email is required"}),
        password: yup.string().required({password:"password is required"}).matches(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,64})",
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
            ),
        userType:yup.string().required({userType:"userType is required"}),
        recaptcha:yup.boolean().oneOf([true],"please verify with reCaptha"),
        termsAndCondidtionCheck:yup.boolean().oneOf([true],"please check terms and condition")
    }),
    login:yup.object().shape({
        email: yup.string().email({email:"email should be email format"}).required({email:"email is required"}),
        password: yup.string().required({password:"password is required"}),
        recaptcha:yup.boolean().oneOf([true],"please verify with reCaptha"),
        termsAndCondidtionCheck:yup.boolean().oneOf([true],"please check terms and condition"),
        userType:yup.string().required({userType:"userType is required"}),
    }),
    updateProfileOnCompletion:yup.object().shape({
    profile_pic: yup.mixed()
     .test('size',
        {profile_pic:['image not greater than 5 mb']}, (value) => !value || (value && value.size <= 1024 * 1024))
      .test('format',
      {profile_pic:['only support png and jpg']}, (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))),
      about:yup.string().max(500,{about:"not more than 500 words"}),
      website_url:yup.string(),


    }),
  
    forgotPassword:yup.object().shape({
        email: yup.string().email({email:"email should be email formal"}).required({email:"email is required"}),
    }),
    newPassword:yup.object().shape({
    newpassword: yup.string().required({newpassword:"password is required"}).matches(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,64})",
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
    }),
    updateProfile:yup.object().shape({
      
          firstName: yup.string().required({firstName:"First Name is required"}).min(2,{firstName:"First Name atleast 3 alphabet"}),
          lastName: yup.string().required({lastName:"Last Name is required"}).min(2,{lastName:"Last Name atleast 3 alphabet"}),
          about:yup.string().notRequired().max(500,{about:"not more than 500 words"}),
          username:yup.string().required({username:"username is required"}).min(3,{username:",username atleast 3 letter"}),
          website_url:yup.string().notRequired(),
          oldpassword: yup.string().notRequired(),
         
         }),
         changeEmail:yup.object().shape({
            email: yup.string().email({email:"email should be email formal"}).required({email:"email is required"}),
            password: yup.string().required({password:"password is required"}).matches(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,64})",
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                ),
             }),
          createCollection:yup.object().shape({
               
                  name: yup.string().required({name:"name is required"}).min(2,{name:"name atleast 3 alphabet"}),
                  description:yup.string().required().max(500,{description:"not more than 500 words"}),
            }),
            createPost:yup.object().shape({
                  postType: yup.string().required({postType:"First Name is required"}),
                  mediaGetting: yup.string().required({mediaGetting:"mediaGetting is required"}),
                  visibiltyMode:yup.string().required({visibiltyMode:"visibiltyMode is required"}),
                  
                  description:yup.string().when("postType",{
                    is:value=>value !=="image",
                    then:yup.string().required({description:"description is required"})
                }),
                  collection:yup.boolean().required({collection:"collection is required"}),
                  resource:yup.boolean().required({resource:"resource is required"}),
                //   tags:yup.array().min(1,{tags:"should be atleast 1 tags"}).required({tags:"tags is required"}),
                  categories:yup.array().min(1,{categories:"should be atleast 1 categories"}).required({categories:"categories is required"}),
                  collections:yup.array().when("collection",{
                      is:true,
                      then:yup.array().min(1,{collections:"should be atleast 1 collection"}).required({collections:"collections is required"})
                  }),
                  resources:yup.array().when("resource",{
                    is:true,
                    then:yup.array().min(1,{resources:"should be atleast 1 resources"}).required({resources:"resources is required"})
                }),
                mediaUrls:yup.array().when("postType",{
                    is:value=>value==="image" || value==="video",
                    then:yup.array().min(1,{mediaUrls:"media should be required"}).required({mediaUrls:"media is reqired"})
                })

}),
createResourceItems:yup.object().shape({
    resourceType: yup.string().required({postType:"First Name is required"}),
    mediaGetting: yup.string().required({mediaGetting:"mediaGetting is required"}),
    visibiltyMode:yup.string().required({visibiltyMode:"visibiltyMode is required"}),
    title:yup.string().required({title:"title is required"}),
    description:yup.string().required({description:"description is required"}),
    tags:yup.array().min(1,{tags:"should be atleast 1 tags"}).required({tags:"tags is required"}),
    categories:yup.array().min(1,{categories:"should be atleast 1 categories"}).required({categories:"categories is required"}),
    resources:yup.array().min(1,{resources:"should be atleast 1 resources"}).required({resources:"resources is required"}),
    mediaUrls:yup.array().min(1,{mediaUrls:"media should be required"}).required({mediaUrls:"media is reqired"})

}),
}