import React from 'react';
import { Link } from 'react-router-dom';

const ContactUs = () => {
    const formSubmit = (e) => {
        e.preventDefault();
    }
    return (<>
        <section className='contact_banner'>
            <div className="container">
                <div className="page-title text-center">
                    <h1>Contact Us</h1>
                    <p>If you need our help, have questions about how to use the platform or are experiencing technical difficulties, please do not hesitate to contact us.</p>
                </div>
            </div>
        </section>
        {/* Form Section */}
        <section className='form-sec'>
            <img src="assets/images/green_butterfly.svg" alt="" className="shape left" />
            <img src="assets/images/pink_butterfly.svg" alt="" className="shape right" />
            <div className="container">
                <div className="form_wrapper">
                    <form onSubmit={formSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="yourName">Your name*</label>
                                <input type="text" className='form-control' placeholder='First Name' name='yourName' required />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="email">Email*</label>
                                <input type="email" className='form-control' placeholder='you@example.com' name='email' required />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="phnNum">Phone number*</label>
                                <input type="text" className='form-control' placeholder='Phone number' name='phnNum' required />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="countrySelect">Country*</label>
                                <select class="form-select" aria-label="Country Select" required>
                                    <option selected>Open this select menu</option>
                                    <option value="1">Pakistan</option>
                                    <option value="2">Indonesia</option>
                                    <option value="3">Iran</option>
                                </select>
                            </div>
                            <div className="col-12">
                                <label htmlFor="messageCategory">Message category*</label>
                                <select class="form-select" aria-label="messageCategory" required>
                                    <option selected>Open this select menu</option>
                                    <option value="1">Report technical issue</option>
                                    <option value="2">Report abuse/harassment</option>
                                    <option value="3">Ask a question</option>
                                    <option value="4">Provide feedback</option>
                                    <option value="5">Other</option>
                                </select>
                            </div>
                            <div className="col-12">
                                <label htmlFor="message">Your message*</label>
                                <textarea name="message" className='form-control' rows="5"></textarea>
                            </div>
                            <div className="butn col-auto mb-0">
                                <button type="submit" className='btn site-btn w-100'><span>Submit</span></button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="cards_wrapper">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="action_card">
                                <div className="head row justify-content-between align-items-center">
                                    <div className="col-auto">
                                        <h2>Email us</h2>
                                    </div>
                                    <div className="col-auto">
                                        <img src="assets/images/email_icon.svg" alt="" className='act_icon' />
                                    </div>
                                </div>
                                <p>Email us for general queries, including marketing and partnership opportunities.</p>
                                <a href="mailto:hello@helpcenter.com" className='cta'>
                                    <span>hello@helpcenter.com</span>
                                    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.4297 0.929688L18.4997 6.99969L12.4297 13.0697" stroke="" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M1.5 7H18.33" stroke="" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="action_card">
                                <div className="head row justify-content-between align-items-center">
                                    <div className="col-auto">
                                        <h2>Support</h2>
                                    </div>
                                    <div className="col-auto">
                                        <img src="assets/images/support_icon.svg" alt="" className='act_icon' />
                                    </div>
                                </div>
                                <p>Check out helpful resources, FAQs and developer tools.</p>
                                <Link to="/#" className='cta'>
                                    <span>Support Center</span>
                                    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.4297 0.929688L18.4997 6.99969L12.4297 13.0697" stroke="" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M1.5 7H18.33" stroke="" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);
}

export default ContactUs;