import { Collection,Follow,Resource } from "../../sdk";
const Search=(props)=>{
    const onHandler=(e)=>{
        let value=e.target.value;
        props.parentState.setState((pre)=>{
            return {...pre,searchinput:value}
        })
    }
    const onSubmit=async ()=>{
       
    }
    return (<>
     <div className="col-4 col-xl-2 col-lg-3">
     <div className="input-group search_field_icon">
     <input type="search" className="form-control border-end-0" placeholder="Search" value={props.parentState.state.searchinput} aria-label="Search Company" aria-describedby="button-search" onChange={onHandler}/>
     {/* <button className="btn shadow-none border-start-0" type="button" id="button-search" onClick={onSubmit}><i className="far fa-search"></i></button> */}
     </div>
     </div>
    </>)
}
export default Search;