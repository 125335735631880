import React, { useState,useEffect,useRef } from 'react';
import CommentContainer from './CommentContainer';
import PostedVideo from './PostedVideo';
import SuggestedPosts from '../../Data/SuggestedPosts';
import UserPost from './UserPost';
import { Loader, toasterFunction } from '../DefaultComponents';
import { Link, useParams } from 'react-router-dom';
import { Post } from '../../sdk';

const PostDetail = () => {
   const [state,setState]=useState({
       loader:true,
       postDetails:[],
       commentLoader:false,
       commentType:"",
       parentId:"",
       commentDescription:"",
       replyDescription:"",
       suggestedPosts:[]
       
   })
   const inputReference = useRef(null);
   const onReply=(commentId)=>{
       
    setState((pre)=>{
        return {...pre,commentType:"reply",parentId:commentId,replyDescription:""}
    })
    }
    const onComment=()=>{
        inputReference.current.focus();
    }
    const onHanlder=(event)=>{
        let key=event.target.name;
        let value=event.target.value;
       
        if(key==="comment"){
            setState((pre)=>{
                return {...pre,commentDescription:value}
            })
        }
        else{
            setState((pre)=>{
                return {...pre,replyDescription:value}
            })
        }

    }
   const params=useParams();
   useEffect(()=>{
   new Post().postDetails(params.id).then((data)=>{
   new Post().getFollowersPost().then((data2)=>{
   setState((pre)=>{
        return {...pre,loader:false,postDetails:data,suggestedPosts:data2}
    })
    }).catch((e)=>{
        console.log(e);
    })
    }).catch((e)=>{
       toasterFunction.error("error occur");
   })
   },[params.id])
   const onCommentSubmit=async (event)=>{
       try{
         event.preventDefault();
          let postId=event.target.getAttribute("data-id");
          let result=await new Post().addComment({post_id:postId,description:state.commentDescription});
          toasterFunction.success("you have comment on this post");
          state.postDetails[0].comments=result.comments
          setState((pre)=>{
              return {...pre,...state}
          })

       }catch(e){
        toasterFunction.error("error occur");
       }
   }
   const onReplySubmit=async (event)=>{
    try{
      event.preventDefault();
      console.log("here")
       let postId=event.target.getAttribute("data-id");
       let result=await new Post().addComment({post_id:postId,description:state.replyDescription,parent_id:state.parentId});
       toasterFunction.success("you have comment on this post");
       state.postDetails[0].comments=result.comments
       setState((pre)=>{
           return {...pre,...state}
       })

    }catch(e){
     toasterFunction.error("error occur");
    }
}
   if(!state.loader){
    return (<>
       
        
        <section className='all-posts post-detail mt-5'>
            <div className="container">
                <div className="row flex-column-reverse flex-lg-row">
                    
                    <div className="col-lg-8">
                        {state.postDetails.map((val)=>{
                           console.log(val.mediaGettingUrl !=="")
                           return (<>
                            <div className="all_posts_col">
                            
                            <UserPost
                                DetailPage={true}
                                data={[val]}
                                parentState={{state:state,setState:setState}}
                                type="details"
                                onReply={onReply}
                                onComment={onComment}
                                
                                
                                
                            />
                           
                            <div className="post-description">
                                <h3>{val.title}</h3>
                                <p>{val.description}</p>

                                {val.mediaGettingUrl !=="" && <p style={{cursor:"pointer",fontSize:"16px",color:"black",fontWeight:"bold"}} onClick={()=>{
                                    window.open(val.mediaGettingUrl, "_blank");
                                }}> {val.postType==="blog"?"view full blog":"view image source"}</p>}
                            </div>
                           
                            <div className="post-categories">
                                <div className="row">
                                   
                                    {val.tags.map((val2)=>{
                                        return(<>
                                        <div className="col-auto">
                                         <div className="filter_item">
                                   <input type="checkbox" checked={true}  readOnly/>
                                    <label htmlFor={val2._id}>
                                      <img src="assets/images/check_mark.svg" alt="Check Mark" />
                                        <span>
                                       {val2.name}
                                     </span>
                                    </label>
                                    </div>
                                    </div>
                                        </>)
                                        
                                    })}
                                    
                                   
                                </div>
                            </div>
                            
                            <div className="post-comments">
                                <h3>{val.comments.length?'Recent Comments':"Be First the comment"}</h3>
                                {val.comments.map((val2, index) => {
                                    return (
                                    <CommentContainer 
                                        key={index}
                                        id={index}
                                        data={val2}
                                        parentState={{state:state,setState:setState}}
                                        type="comment"
                                        onReply={onReply}
                                        onHanlder={onHanlder}
                                        onSubmit={onReplySubmit}
                                    />
                                    )
                                })}
                                
                            </div>
                            {/* Write Comments */}
                            <div className="write_comment">
                                <form onSubmit={onCommentSubmit} data-id={val._id}>
                                    <label htmlFor="post_comment">Write your Comment</label>
                                    <div className="d-flex">
                                        <input type="text" className='form-control' ref={inputReference} name="comment" onChange={onHanlder} value={state.commentDescription} placeholder='Dorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod' />
                                        <button type="submit" className='btn site-btn'><span><img src="assets/images/plain.svg" alt="Paper Plane" /></span></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                           </>)
                        })}
                        
                    </div>
                    {/* Column 2 */}
                    {/* <div className="col-lg-4 mb-5">
                        <div className="suggestions">
                            <FollowOptCard
                                adminImg='assets/images/brittni.png'
                                adminImgDes='Admin Profile Img'
                                adminFname='Brittni Lando'
                                adminUserName='@Brittniland'
                                cardClass={false}
                            />
                            <div className="author_info">
                                <h3>About Brittni Lando</h3>
                                <p>Dorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua pt enim ad minim. Dorem ipsum dolor sit amet, consectetur</p>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="suggested_posts">
                    {/* Title */}
                    <h2 className="title text-md-center">You Might Also Like These</h2>
                    <div className="row">
                        {state.suggestedPosts.filter(val=>val.media.length && val._id !==params.id).map((val,index) => {
                            if(index <3){
                                return (
                                    <div className="col-md-4" key={val._id}>
                                        <Link to={`/postdetails/${val._id}`} className='d-block'>
                                            <div className="post-visual">
                                                <PostedVideo
                                                    postVideoSrc={val.postVideoSrc}
                                                    postVideoImg={val.media[0].media_url}
                                                />
                                            </div>
                                        </Link>
                                    </div>
                                );
        
                            }
                            else{
                                return false;
                            }
                                               })}
                    </div>
                </div>
            </div>
        </section>
    </>);
   }else{
       return (<>
       <Loader/>
       </>)
   }
   
}

export default PostDetail;