import React from 'react';

const VideoOutput = (props) => {
  console.log(props.data)
 return (<>
    {props.data.map((val,index)=>{
     return (<>
     {/* <button className='btn p-0 lh-1' type="button" onClick={()=>props.deleteFun(index,props.type)}><i className='fal fa-times'  ></i></button> */}
     <div style={{backgroundColor:props.selected.includes(val)?"#0000006b":"#e3e3e3",cursor:"pointer"}}>
     {props.selected.includes(val) && <><img src='assets/images/check-mark-29114_1280.webp' style={{position:"absolute",width:"20%"}}/></>}
     <video width="165" height="165" controls  onClick={()=>props.selectImage(val)}>
     
     <source src={val} type="video/mp4"/>
     </video>
     </div>
     
        
      </>)
    })}
    
    
   {
       ((props.type !=="blog" &&props.mediaLoader) && <> <div class="spinner-border uploader_spinner" style={{width:"3rem",height:"3rem"}} role="status">
       <span class="visually-hidden">Loading...</span>
     </div></>)
   }
    {(props.type !=="blog" &&props.mediaLoader) && props.errors.mediaUrls.map((val,index)=>{
                                             return (<>
                                             <p className='form_error'>{val}</p> 
                                             </>)
                                           })}
   

    
   
        
    </>);
}

export default VideoOutput;