import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { selector,dispatch as parentDispatch } from '../../redux';
import { getCategoriesPostThunk } from '../../redux/Thunk';
import { CategorySkeleton } from '../Skeleton';

const BannerFilters = () => {
    const categoryPost=selector(state=>state.categoryPost);
    const dispatch=parentDispatch();
    const onHandler=(categoryId=false,subCatid=false,limit=4)=>{
     
        dispatch(getCategoriesPostThunk(categoryId,subCatid,limit))
       }
       console.log(categoryPost);
    return (<>
    {categoryPost.loading && <CategorySkeleton/>}
    {!categoryPost.loading && <OwlCarousel key={new Date()} className='filter_banner_slider owl-theme' dots={false} autoWidth={true} navText={['<i class="far fa-arrow-left"></i>', '<i class="far fa-arrow-right"></i>']} responsive={{ 0: { margin: 10, center: true }, 768: { margin: 20, center: false, } }} loop nav>
            {categoryPost.data.filterItems.map((val)=>{
                
             return (<>
               <div key={val._id} className="item">
               <div className="filter_item">
               <input type="checkbox" checked={(val.subCategories)?categoryPost.data.userCatSelectArr.includes(val._id):categoryPost.data.userSubCatSelectArr.includes(val._id)} onChange={()=>(val.subCategories)?onHandler(val._id,false,4):onHandler(val.category_id,val._id,4)} value={val._id}/>
               <label htmlFor={val._id}>
                <img src="assets/images/check_mark.svg" alt="Check Mark" />
                <span>
                    {val.name}
                </span>
            </label>
        </div>
            </div>
               
               </>)
            })}
            
           
           
            
           
            
            
        </OwlCarousel>}
        
    </>);
}

export default BannerFilters;