import React from 'react';
import { selector } from '../../redux';
import BannerFilters from './BannerFilters';
const BannerSection = (props) => {
const categoryPost=selector(state=>state.categoryPost);
 return (<>
        <section className="banner">
            <div className="container">
                {/* <!-- Page Title --> */}
                <div className="page-title text-center">
                    <h1>{categoryPost.data.bannerName}</h1>
                    <p>To Become The Best Version Of Themselves</p>
                </div>
                {/* Banner Filtr Slider */}
                <BannerFilters />
            </div>
        </section>
    </>);
}

export default BannerSection;