import { Encryption, Storage } from "../helpers";
import { axios } from "./config"
export class User extends axios {
    axios;
    token;
    prefix;
    constructor() {
        super();
        this.axios = this.axiosConfig();
        this.token = Storage.getCookie(`${process.env.NODE_ENV}-userToken`);
        this.prefix = "/user/"
    }
    async updateProfile(data) {
        try {
            let formData = new FormData();
            if (Object.keys(data).length > 0) {
                for (let key in data) {
                    if (key === "profile_pic") {
                        if (data[key] && data[key] !== "") {
                            formData.append(key, data[key]);
                        }
                    }
                    else {
                        formData.append(key, data[key]);
                    }

                }
                let result = await this.axios({
                    method: "post",
                    url: this.prefix.concat("updateprofile"),
                    data: formData,
                    headers: {
                        Authorization: this.token,
                        'content-type': 'multipart/form-data'
                    }
                });
                return new Promise((res, rej) => {
                    res(result.data);
                })
            }

        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async getUserData(id = false) {
        try {
            let result = await this.axios({
                method: "post",
                url: (id) ? this.prefix.concat("getUserData/").concat(id) : this.prefix.concat("getUserData"),
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })
        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }

    }
    async interestToggle(data) {
        try {
            let newArr = data.map((val) => {
                return Encryption.encryptBase64(val);
            })

            let result = await this.axios({
                method: "post",
                url: this.prefix.concat("interstToggle"),
                data: { subcategory_id: newArr },
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })


        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async changeEmail(data) {
        try {

            let result = await this.axios({
                method: "post",
                url: this.prefix.concat("changeEmail"),
                data: data,
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })


        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async changeSocialPassword(data) {
        try {

            let result = await this.axios({
                method: "post",
                url: this.prefix.concat("socialloginpassword"),
                data: data,
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })


        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async verfiyEmailForChangePassword(data) {
        try {

            let result = await this.axios({
                method: "post",
                url: this.prefix.concat("verifyemailforpassword"),
                data: data,
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })


        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async sendEmailForChangePassword() {
        try {

            let result = await this.axios({
                method: "post",
                url: this.prefix.concat("sendverificationemailforpassword"),
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })


        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async changeEmailVerify(data) {
        try {
            let result = await this.axios({
                method: "post",
                url: this.prefix.concat("changeEmailVerfication"),
                data: data,
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })
        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })

        }
    }
    async updateNotificationSettings(data) {
        try {

            if (Object.keys(data).length > 0) {

                let result = await this.axios({
                    method: "post",
                    url: this.prefix.concat("changeSettings"),
                    data: data,
                    headers: {
                        Authorization: this.token
                    }
                });
                return new Promise((res, rej) => {
                    res(result.data);
                })
            }

        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async userCategories() {
        try {
            let result = await this.axios({
                method: "post",
                url: this.prefix.concat("userCategories"),
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })
        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }

    }
    async commingSoon(data) {
        try {
            let result = await this.axios({
                method: "post",
                url: this.prefix.concat("commingSoon"),
                data,
            });
            return new Promise((res, rej) => {
                res(result.data);
            })
        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async deleteUser(data) {
        try {
            let result = await this.axios({
                method: "post",
                url: this.prefix.concat("deleteUser"),
                data: data,
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result);
            })
        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })

        }
    }
}