import {useState} from 'react';
import { dataValidation, passwordStrength, Validate} from './../../helpers';
import { toasterFunction } from "../DefaultComponents"
import {Auth} from "./../../sdk"
import { useNavigate } from "react-router-dom";
export const NewPassword=({userId,parentState})=>{
    let navigate = useNavigate();
    const [state,setState]=useState({
        newpassword:"",
        user_id:userId,
        errors:{
           newpassword: [],
           other:[],
        },
        passwordStrength:{
            veryWeak:{background:""},
            weak:{background:""},
            strong:{background:""},
            veryStrong:{background:""}
        },
        validity:"",
        buttonDisable:false,
        submit:false,
        loader:false,
        passwordType:"password",
    });
    const onSubmit=async (event)=>{
        event.preventDefault();
        setState((pre)=>{
            return {...pre,loader:true}
        })
        try{
           await Validate.newPassword.validate(state,{abortEarly: false});
           await new Auth().newPassword({newpassword:state.newpassword,user_id:state.user_id});
           navigate("/");
           toasterFunction.success("Password changed successfully");
           setState((pre)=>{
            return {...pre,loader:false,buttonDisable:false}
        })
        parentState((pre)=>{
            return {...pre,resetPopUp:false}
        })
       
        }catch(e){
            let obj={
               newpassword:[],
               other:[]
            }
           let validity=""
          
             if(e.errors && e.errors.length > 0){
             for(let error of e.errors){
             if(typeof(error)==="string"){
                obj["newpassword"].push(error);
                validity="error-class"; 
             }
             else{
                obj[Object.keys(error)[0]].push(Object.values(error)[0]);
                validity="error-class";
             }
             
            }
            
         }
         else if(e.response){
         let keys=Object.keys(e.response.data);
         obj[keys[0]]=e.response.data[keys[0]]
         validity="error-class";
         }
         else{
             obj.other.push("Error Occur! Please Contact your Adminstration");
             
         }
         setState((pre)=>{
            return{...pre,errors:obj,validity:validity,submit:true,loader:false}
        })
      
        }
       }
    const onHandler=(event)=>{
        let key=event.target.name;
        let value=event.target.value;
        let oldPasswordStrength=state.passwordStrength;
        let oldValidity=state.validity;
        if(key==="password"){
            oldPasswordStrength=new passwordStrength(value).checkPassword();
        }
        let newValidity=(new dataValidation(key,value,state.submit)).validate()?{...oldValidity,[key]:""}:{...oldValidity,[key]:"error-class"};
       
       setState((pre)=>{
            return {...pre,newpassword:value,passwordStrength:oldPasswordStrength,validity:newValidity};
        })
    }

    return (<>
     <div className="modal fade join_form_popup show" id="newPwd" tabIndex="-1" aria-labelledby="newPwdLabel" style={{paddingRight:"17px",display:"block",background:"#00000094"}} aria-hidden="true">
    <div className="modal-dialog">
        <div className="modal-content">
            <form onSubmit={onSubmit}>
                <div className="joinus_form login_form">
                    <div className="modal-header justify-content-center border-0 pb-0">
                        <h2 className="modal-title" id="newPwdLabel">Forgot Password</h2>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body pt-0">
                        <div className="with-email">
                            <h3>Enter New Password</h3>
                            <div className="mb-3 password position-relative">
                                <label htmlFor="newPwd" className="form-label">Password</label>
                                <div className="position-relative">
                                <input type={state.passwordType} className={`form-control ${state.validity}`} name="password" id="newPwd" placeholder="Enter Password" onChange={onHandler} value={state.newpassword} required />
                                {state.errors.newpassword.map((val,index)=>{
                                             return (<>
                                             <p className='form_error'>{val}</p> 
                                             </>)
                                           })}
                                <button className="btn p-0 pwd-btn" type='button'><i className="fas fa-eye-slash"onClick={()=>{
                                               if(state.passwordType==="password"){
                                                   setState((pre)=>{
                                                       return {...pre,passwordType:"text"}
                                                   })
                                               }
                                               else{
                                                setState((pre)=>{
                                                    return {...pre,passwordType:"password"}
                                                })
                                               }
                                           }}></i></button>
                                </div>
                                <div className="pwd-power d-flex justify-content-between">
                                           <span style={state.passwordStrength.veryWeak}></span>
                                           <span style={state.passwordStrength.weak}></span>
                                           <span style={state.passwordStrength.strong}></span>
                                           <span style={state.passwordStrength.veryStrong}></span>
                                       </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer align-items-center flex-wrap border-0 pt-0">
                        {/* <!-- Continue Btn --> */}
                        <div className="col-12">
                            <button type="submit" className="btn site-btn w-100" disabled={state.buttonDisable}><span>Continue  {(state.loader)?<div className="spinner-border"style={{width:"1rem",height:"1rem"}}></div>:""}</span></button>
                        </div>
                        <div className="col-12 termsCheck d-flex justify-content-center">
                            <label>
                                Go Back to
                                <a type="button" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#loginForm">Login</a>
                            </label>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
    </>)
   
}