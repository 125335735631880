import { Post } from "../../sdk";
import { Categories_Post } from "../ActionConstants";
export const getCategoriesPostThunk=(catId=false,subCatId=false,limit=4,userId=false)=>{
return async (dispatch,getState)=>{
    try{
    let {categoryPost}=getState();
    if(!catId && !subCatId ){
        let result=await new Post().getCategoryPost({catIds:[],subCatIds:[],limit,userId});
        let filteredPost=[];
        let filterItems=[];
        let bannerName=categoryPost.data.bannerName
        if(result.length){
            for(let val of result){
                filteredPost.push(val);
                filterItems.push(val)
            }
        }
        let data={
            filterPost:filteredPost,
            filterItems:filterItems,
            bannerName:bannerName,
            userSubCatSelectArr:[],
            userCatSelectArr:[],
            limit

        }
        dispatch({type:Categories_Post.success,payload:data})
    }
    else if(catId && !subCatId){
        let oldCatIds=categoryPost.data.userCatSelectArr;
        let newCatIds=[]
        newCatIds.push(catId);
        // if(limit > 4){
          
        // }
        // else{
        //     if(oldCatIds.includes(catId)){
        //         newCatIds=oldCatIds.filter((val)=>val!==catId)
        //     }
        //     else{
        //         oldCatIds.push(catId);
        //         newCatIds=oldCatIds;
                
        //     }
        // }
       let result=await new Post().getCategoryPost({catIds:newCatIds,subCatIds:[],limit,userId});
       let filteredPost=[];
       let filteredItem=[]
       let bannerName=categoryPost.data.bannerName
       if(result.length){
           bannerName=result[0].name;
           for(let val of result){
                       if(val.subCategories){
                          for(let subcategory of val.subCategories){
                                filteredPost.push(subcategory);
                                filteredItem.push(subcategory);
                          }
                       }
                   }
       }
       let data={
        userSubCatSelectArr:[],
        filterPost:filteredPost,
        bannerName:bannerName,
        filterItems:filteredItem,
        userCatSelectArr:newCatIds,
        limit
    }
        dispatch({type:Categories_Post.success,payload:data})
     }
     else{
        let oldSubCatIds=categoryPost.data.userSubCatSelectArr;
        let newSubCatIds=[]
        if(limit > 4){
          newSubCatIds.push(subCatId);
        }
        else{
            if(oldSubCatIds.includes(subCatId)){
                newSubCatIds=oldSubCatIds.filter((val)=>val!==subCatId)
            }
            else{
                oldSubCatIds.push(subCatId);
                newSubCatIds=oldSubCatIds;
                
            }
        }
       let result=await new Post().getCategoryPost({catIds:[catId],subCatIds:newSubCatIds,limit,userId});
       let filteredPost=[];
       let filteredItem=[]
       let bannerName=categoryPost.data.bannerName
       if(result.length){
           bannerName=result[0].name;
           
           for(let val of result){
                       if(val.subCategories){
                          for(let subcategory of val.subCategories){
                               if(newSubCatIds.includes(subcategory._id)){
                                filteredPost.push(subcategory);
                               }
                                filteredItem.push(subcategory);
                          }
                       }
                   }
       }
       let data={
        userSubCatSelectArr:newSubCatIds,
        filterPost:filteredPost,
        bannerName:bannerName,
        filterItems:filteredItem,
        userCatSelectArr:[catId],
        limit
    }
        dispatch({type:Categories_Post.success,payload:data})
     }
    
     
    }catch(e){
        
    dispatch({type:Categories_Post.error,payload:e.response})
        }
        }
}