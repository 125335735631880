import { User } from "../../sdk"
import { Get_User } from "../ActionConstants";
export const  userDataThunk=(id=false)=>{
    return async (dispatch)=>{
        try{
        let result=await new User().getUserData(id);
        result={...result,id:result._id}
        dispatch({type:Get_User.success,payload:result});
        }catch(e){
        dispatch({type:Get_User.error,payload:e.response});
        }
    }
}