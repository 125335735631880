import { toasterFunction } from './Toaster';
import { useAuthentication } from '../../customHooks';
import { Auth } from '../../sdk';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
export const GoogleSocialLogin=(props)=>{
    const [afterAuthentication]=useAuthentication();
    const navigate=useNavigate();
    const responseGoogle=async(token)=>{
        try{
          let result;
             if(props.type==="register"){
              result=await new Auth().socialRegister({
                userType:"user",
                logintype:"google",
                token:token,
            });
             }
             else{
              result=await new Auth().socialLogin({
                userType:"user",
                logintype:"google",
                token:token,
            });
             }
           
           afterAuthentication(result);
           if(props.type==="register" || !result.alreadyRegistered){
            navigate("/complete-profile");
          }
           }catch(e){
           toasterFunction.error(Object.values(e.response.data)[0][0]);
          
              
           }
    }
    const failGoogleResponse=()=>{
        toasterFunction.error("error occur ! Please Try again");
    }
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://apis.google.com/js/platform.js';
        script.async = true;
        document.body.appendChild(script);
        script.addEventListener('load', () => {
          console.log('Google isLoaded');
          window.gapi.load('auth2', () => {
            
          window.auth2 = window.gapi.auth2.init({
              client_id: process.env.REACT_APP_GOOGLE_ID,
              cookiepolicy: 'single_host_origin',
              fetch_basic_profile: true,
              scope: 'profile email',
              plugin_name: "chat"
            });
            window.auth2.attachClickHandler(document.getElementById("gbtn"), {}, googleUser => {
              let authRes = googleUser.getAuthResponse();
              responseGoogle(authRes.id_token)
        
            });
          });
        });
        return ()=>{
          script.remove();
        }
      
      
      }, []);
    return (
        <>
          {/* <GoogleLogin
         clientId={process.env.REACT_APP_GOOGLE_ID}
         render={renderProps => (
         
         
         )}
        buttonText="Login"
        onSuccess={responseGoogle}
        onFailure={failGoogleResponse}
        isSignedIn={false} 
       
   /> */}
     <a id="gbtn" className="btn site-btn google" ><span><i className="fab fa-google"></i> {props.name}</span></a>
        </>
    )
}