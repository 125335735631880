import React from 'react';
import UserPost from './UserPost';
import CompanySearch from './CompanySearch';
import { Post,Follow } from '../../sdk';
import { useState,useEffect } from 'react';
import { Loader, toasterFunction } from '../DefaultComponents';
import { Encryption} from '../../helpers';
import { useAuthContext } from '../../context';
import { selector } from '../../redux';
import { useNavigate } from 'react-router-dom';


const Home = (props) => {
    const [state,setState]=useState({
        loader:true,
        postDetails:[],
        suggestion:{company:[],user:[]},
    })
    const navigate=useNavigate();
    const ownUser=selector(state=>state.ownUser);
    const userAuth=useAuthContext();
    const followers=ownUser.data.followers.map((val)=>{
    return val.followByUser._id
    })
    
    useEffect(()=>{
        new Post().getFollowersPost().then((data)=>{
            setState((pre)=>{
                return {...pre,loader:true,postDetails:data}
            })
        new Follow().followerSuggestion().then((data2)=>{
            setState((pre)=>{
                return {...pre,loader:false,suggestion:data2}
            })
        }).catch((e)=>{
            setState((pre)=>{
                return {...pre,loader:false,suggestion:[]}
            })
            toasterFunction.error("No Post Found");
        })
        
        }).catch((e)=>{
            setState((pre)=>{
                return {...pre,loader:false,postDetails:[]}
            })
            toasterFunction.error("No post Found");
        })
        },[])
        const redirect=(id)=>{
            navigate("/profile/"+id)
        }
        const followToggle=async(userId,type="follwer",add=false)=>{
            try{
                
            let result=await new Follow().followToggle({follow_to:Encryption.encryptBase64(userId)});
            let suggestion=await new Follow().followerSuggestion();
            toasterFunction.success(result.message);
            setState((pre)=>{
            return {...pre,suggestion:suggestion}
            })
            userAuth.updateState(result.data);
            }catch(e){
                 toasterFunction.error("error occur")
                    }
                }   
    let CompanyPage = props.CheckCompanyPage;
    if(!state.loader){
        return (<>
            <section className='all-posts mt-5'>
                  <div className="container">
                      {/* Company Search */}
                      {CompanyPage ? <CompanySearch /> : ''}
                      {/* Post Row */}
                      <div className="row flex-column-reverse flex-lg-row">
                          {/* Column 1 */}
                          <div className="col-lg-8">
                              <div className="all_posts_col">
                                  {/* Posts */}
                                  {state.postDetails.map((val) => {
                                      if(props.CheckCompanyPage ){
                                          if(val.user && val.user.userType==="company"){
                                            return (
                                                <UserPost
                                                DetailPage={true}
                                                data={[val]}
                                                parentState={{state:state,setState:setState}}
                                                type="home"
                                                />
                                            );
                                          }
                                        
                                      }
                                      else if(val.user && val.user.userType==="user"){
                                        return (
                                            <UserPost
                                            DetailPage={true}
                                            data={[val]}
                                            parentState={{state:state,setState:setState}}
                                            type="home"
                                            />
                                        );
                                      }
                                     
                                  })}
                              </div>
                          </div>
                          {/* Column 2 */}
                          <div className="col-lg-4 mb-5">
                              <div className="suggestions">
                                  <h3>You Should Follow</h3>
                                  {(CompanyPage && state.suggestion && state.suggestion.company && Array.isArray(state.suggestion.company)) && 
                                  state.suggestion.company.map((val) => {

                                      return (
                                        <div className={`follow-card-opt`}>
                                        <div className="card-admin d-flex flex-wrap justify-content-between">
                                            <div className="admin-info d-flex align-items-center" onClick={()=>redirect(Encryption.encryptBase64(val._id))}>
                                                <img src={(val.profile_pic)?val.profile_pic:"assets/images/avatar.jpg"}  />
                                                <div className="admin-name">
                                                    <p>{val.firstName} {val.lastName}</p>
                                                    <span>@{val.username}</span>
                                                </div>
                                            </div>
                                            {(followers.includes(val._id))? <button className='site-btn'  onClick={()=>{return followToggle(val._id)}}><span>Follow Back{(state.loader)?<div className="spinner-border"style={{width:"1rem",height:"1rem"}}></div>:""}</span></button>:<button className='site-btn'  onClick={()=>{return followToggle(val._id)}}><span>Follow</span></button>}
                                        </div>
                                       
                                    </div>
                                      );
                                  })}
                                  {(!CompanyPage && state.suggestion && state.suggestion.user && Array.isArray(state.suggestion.user)) &&  
                                  state.suggestion.user.map((val) => {
                                      return (
                                        <div className={`follow-card-opt`}>
                                        <div className="card-admin d-flex flex-wrap justify-content-between">
                                            <div className="admin-info d-flex align-items-center"  onClick={()=>redirect(Encryption.encryptBase64(val._id))}>
                                                <img src={(val.profile_pic)?val.profile_pic:"assets/images/avatar.jpg"}  />
                                                <div className="admin-name">
                                                    <p>{val.firstName} {val.lastName}</p>
                                                    <span>@{val.username}</span>
                                                </div>
                                            </div>
                                            {(followers.includes(val._id))? <button className='site-btn'  onClick={()=>{return followToggle(val._id)}}><span>Follow Back{(state.loader)?<div className="spinner-border"style={{width:"1rem",height:"1rem"}}></div>:""}</span></button>:<button className='site-btn'  onClick={()=>{return followToggle(val._id)}}><span>Follow</span></button>}
                                            
                                        </div>
                                       
                                    </div>
                                      );
                                  })}
                                 
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
          </>);
    }
    else{
        return (<>
            <Loader/>
            </>)
    }
   
}

export default Home;