import {useState,useCallback,memo} from "react"
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context";
import { Encryption, Modal } from "../../helpers";
import { dispatch as parentDispatch } from "../../redux";
import { OwnUserUpdateByKey } from "../../redux/Actions";
import { Follow } from "../../sdk";
import { toasterFunction } from "../DefaultComponents";
import CollectionCard from './CollectionCard';

const FollowOptCard = (props) => {
const [state,setState]=useState({
        loader:false,
        buttonDisable:false
    })
    const navigate=useNavigate();
    const redirect=(id)=>{
        navigate("/profile/"+id)
    }
const userAuth=useAuthContext();
const dispatch=parentDispatch();
let userDetails=props.userDetails;

const followToggle=useCallback(async(userId,type)=>{
        try{
            if(!userAuth.user){
            Modal.showTab("#loginForm");
                return false;
            }
                setState((pre)=>{
                    return {...pre,loader:true,buttonDisable:true}
                })
                let result=await new Follow().followToggle({follow_to:Encryption.encryptBase64(userId)});
                dispatch(OwnUserUpdateByKey(type,result.data[type]))
                setState((pre)=>{
                    return {...pre,loader:false,buttonDisable:false}
                })
                toasterFunction.success(result.message);
                }catch(e){
                console.log(e);
                toasterFunction.error("something get wrong");
            }
            }
,[userAuth,dispatch])
 if((userAuth.user !==null && userAuth.user.id !==userDetails._id) || userAuth.user ==null)  {
    if(props.friendStatus.followers.includes(userDetails._id)){
        return (<>
            <div className={`follow-card-opt`}>
                <div className="card-admin d-flex flex-wrap justify-content-between" onClick={()=>redirect(Encryption.encryptBase64(userDetails._id))}>
                    <div className="admin-info d-flex align-items-center" >
                        <img src={(userDetails.profile_pic)?userDetails.profile_pic:"assets/images/avatar.jpg"}  alt="nothing"/>
                        <div className="admin-name">
                            <p>{userDetails.firstName} {userDetails.lastName}</p>
                            <span>@{userDetails.username}</span>
                        </div>
                    </div>
                    {/* <p className='follow_text'><img src="assets/images/check_mark.svg" alt="Check Mark" className='me-1' /> Following</p>   */}
                    <button className='site-btn' disabled={state.buttonDisable} onClick={()=>{return followToggle(userDetails._id,"followers")}}><span>Follow Back{(state.loader)?<div className="spinner-border"style={{width:"1rem",height:"1rem"}}></div>:""}</span></button>
                    {/* <button className='site-btn' onClick={()=>{return followToggle(userDetails._id,type)}} disabled={state.buttonDisable}><span>Unfollow {(state.loader)?<div className="spinner-border"style={{width:"1rem",height:"1rem"}}></div>:""}</span></button>) */}
                </div>
                {(props.dashBoard && userAuth.isAuthenticated && props.type !=="completeProfile") && <CollectionCard postDetails={props.postDetails} onClickTarget={props.onClickTarget}  postId={props.postId}/>}
            </div>
        </>);
    }
    else if(props.friendStatus.followBack.includes(userDetails._id)){
        return (<>
            <div className={`follow-card-opt`}>
                <div className="card-admin d-flex flex-wrap justify-content-between">
                    <div className="admin-info d-flex align-items-center" onClick={()=>redirect(Encryption.encryptBase64(userDetails._id))}>
                        <img src={(userDetails.profile_pic)?userDetails.profile_pic:"assets/images/avatar.jpg"}  alt="nothing"/>
                        <div className="admin-name">
                            <p>{userDetails.firstName} {userDetails.lastName}</p>
                            <span>@{userDetails.username}</span>
                        </div>
                    </div>
                    <div class="follow-wrap">
                        <p className='follow_text'><img src="assets/images/check_mark.svg" alt="Check Mark" className='me-1' /> Following</p>   
                        <button className='site-btn' onClick={()=>{return followToggle(userDetails._id,"followers")}} disabled={state.buttonDisable}><span>Unfollow {(state.loader)?<div className="spinner-border"style={{width:"1rem",height:"1rem"}}></div>:""}</span></button>
                    </div>
                </div>
                {(props.dashBoard && userAuth.isAuthenticated && props.type !=="completeProfile") && <CollectionCard postDetails={props.postDetails} onClickTarget={props.onClickTarget}  postId={props.postId}/>}
            </div>
        </>);
    }
    else if(props.friendStatus.followings.includes(userDetails._id)){
        
        return (<>
            <div className={`follow-card-opt`}>
                <div className="card-admin d-flex flex-wrap justify-content-between">
                    <div className="admin-info d-flex align-items-center" onClick={()=>redirect(Encryption.encryptBase64(userDetails._id))}>
                        <img src={(userDetails.profile_pic)?userDetails.profile_pic:"assets/images/avatar.jpg"} alt="nothing" />
                        <div className="admin-name">
                            <p>{userDetails.firstName} {userDetails.lastName}</p>
                            <span>@{userDetails.username}</span>
                        </div>
                    </div>
                    <div class="follow-wrap">
                        <p className='follow_text'><img src="assets/images/check_mark.svg" alt="Check Mark" className='me-1' /> Following</p>
                        <button className='site-btn' onClick={()=>{return followToggle(userDetails._id,"followings")}} disabled={state.buttonDisable}><span>Unfollow {(state.loader)?<div className="spinner-border"style={{width:"1rem",height:"1rem"}}></div>:""}</span></button>
                    </div>
                   
                </div>
                {(props.dashBoard && userAuth.isAuthenticated && props.type !=="completeProfile") && <CollectionCard postDetails={props.postDetails} onClickTarget={props.onClickTarget}  postId={props.postId}/>}
            </div>
        </>);
    }
    else{
        return (<>
            <div className={`follow-card-opt`}>
                <div className="card-admin d-flex flex-wrap justify-content-between">
                    <div className="admin-info d-flex align-items-center" onClick={()=>redirect(Encryption.encryptBase64(userDetails._id))}>
                        <img src={(userDetails.profile_pic)?userDetails.profile_pic:"assets/images/avatar.jpg"}  alt="nothing"/>
                        <div className="admin-name">
                            <p>{userDetails.firstName} {userDetails.lastName}</p>
                            <span>@{userDetails.username}</span>
                        </div>
                    </div>
                    <button className='site-btn' disabled={state.buttonDisable} onClick={()=>{return followToggle(userDetails._id,"followings")}}><span>Follow{(state.loader)?<div className="spinner-border"style={{width:"1rem",height:"1rem"}}></div>:""}</span></button>
                </div>
                {(props.dashBoard && userAuth.isAuthenticated && props.type !=="completeProfile") && <CollectionCard postDetails={props.postDetails} onClickTarget={props.onClickTarget}  postId={props.postId}/>}
    
            </div>
        </>);
    }
 }
 else{
    return (<>
        <div className={`follow-card-opt`}>
            <div className="card-admin d-flex flex-wrap justify-content-between">
                <div className="admin-info d-flex align-items-center">
                    <img src={(userDetails.profile_pic)?userDetails.profile_pic:"assets/images/avatar.jpg"}  alt="nothing"/>
                    <div className="admin-name">
                        <p>{userDetails.firstName} {userDetails.lastName}</p>
                        <span>@{userDetails.username}</span>
                    </div>
                </div>
                
            </div>
            {(props.dashBoard && userAuth.isAuthenticated && props.type !=="completeProfile") && <CollectionCard postDetails={props.postDetails} onClickTarget={props.onClickTarget}  postId={props.postId}/>}

        </div>
    </>);
 }






}

export default memo(FollowOptCard);
