import { useState } from "react"
import { toasterFunction } from "../DefaultComponents"
import {Auth} from "./../../sdk"
import {Validate,dataValidation} from './../../helpers';
export const ResetPassword=()=>{
const [state,setState]=useState({
    email:"",
    error:"",
    loader:false,
    errors:{
        email: [],
        other: [],
        
    },
    validity:"",
    submit:false,
    buttonDisable:false,
});
const onSubmit=async(event)=>{
    try{
     event.preventDefault();
        setState((pre)=>{
            return {...pre,loader:true,buttonDisable:true}
        })
        await Validate.forgotPassword.validate(state,{abortEarly: false});
        await new Auth().forgotPassword(state.email);
        setState((pre)=>{
            return {...pre,loader:false,buttonDisable:false}
        })
        toasterFunction.success("email has been sent , Please Check your mail box");
       }catch(e){
        let obj={
            email:[],
            other:[]
        }
       let validity="";
       if(e.errors && e.errors.length > 0){
        for(let error of e.errors){
        obj[Object.keys(error)[0]].push(Object.values(error)[0]);
        validity="error-class";
      }
     }
     else if(e.response){
        let keys=Object.keys(e.response.data);
        obj[keys[0]]=e.response.data[keys[0]]
        validity="error-class";
     }
     else{
        obj.other.push("Error Occur! Please Contact your Adminstration");
     }
    setState((pre)=>{
            return{...pre,errors:obj,validity:validity,loader:false,submit:true,buttonDisable:false}
    })
    }
   }
   const onHandler=(event)=>{
    let key=event.target.name;
    let value=event.target.value;
    let oldValidity="";
    let newValidity=(new dataValidation(key,value,state.submit)).validate()?{...oldValidity,[key]:""}:{...oldValidity,[key]:"error-class"};
   setState((pre)=>{
        return {...pre,[key]:value,validity:newValidity};
    })
}
     return (<>
    <div className="modal fade join_form_popup" id="forgotPwd" tabIndex="-1" aria-labelledby="forgotPwdLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <form onSubmit={onSubmit}>
                        <div className="joinus_form login_form">
                            <div className="modal-header justify-content-center border-0 pb-0">
                                
                                <h2 className="modal-title" id="forgotPwdLabel">Forgot Password</h2>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                            {state.errors.other.map((val,index)=>{
                                             return (<>
                                             <p className='form_error'>{val}</p> 
                                             </>)
                                           })}
                                <div className="with-email">
                                    <h3>Forgot with Email</h3>
                                    <div className="mb-3">
                                        <label htmlFor="forgotEmail" className="form-label">Email address</label>
                                        <input type="email" className={`form-control ${state.validity}`} name="email" id="forgotEmail" onChange={onHandler} value={state.email} placeholder="sophíabelli@websitename.com" required />
                                        {state.errors.email.map((val,index)=>{
                                             return (<>
                                             <p className='form_error'>{val}</p> 
                                             </>)
                                           })}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer align-items-center flex-wrap border-0 pt-0">
                                {/* <!-- Continue Btn --> */}
                                <div className="col-12">
                                    <button type="submit" className="btn site-btn w-100" disabled={state.buttonDisable}><span>Reset Password{(state.loader)?<div className="spinner-border"style={{width:"1rem",height:"1rem"}}></div>:""}</span></button>
                                </div>
                                <div className="col-12 termsCheck d-flex justify-content-center">
                                    <label>
                                        Go Back to <a type="button" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#loginForm">Login</a>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>)
}