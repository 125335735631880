import React, { useState,useEffect} from 'react';
import { Post,Categories, Resource } from '../../sdk';
import PostFields from './PostFields';
import UploaderField from '../Addposts/UploaderField';
import { Loader, toasterFunction } from '../DefaultComponents';
import { Validate } from '../../helpers';
import VideoOutput from '../Addposts/VideoOutput';

const AddResource = () => {
    const [state, setState] = useState({
        formData: {
            resourceType: "pdf",
            mediaGetting: "default",
            visibiltyMode: "private",
            title: "",
            description: "",
            mediaUrls: [],
            tags: [],
            categories: [],
            resources: []
        },
        errors: {
            resourceType: [],
            mediaGetting: [],
            visibiltyMode: [],
            title: [],
            description: [],
            mediaUrls: [],
            tags: [],
            categories: [],
            resources: [],
            other: []
        },
        loader: false,
        buttonDisable: false,
        preLoader: false,
        categories: [],
        userTags: [],
        mediaLoader: false,
        mediainputDisable: false,
    })
    useEffect(()=>{
        new Categories().getCategories().then((data)=>{
        setState((pre)=>{
              return {...pre,categories:data,preLoader:false};
          })
        }).catch((e)=>{
          setState((pre)=>{
              return {...pre,preLoader:false};
          })
        })
     },[])
    const uploadMedia = async (media, key) => {
        try {
            setState((pre) => {
                return {
                    ...pre, loader: true, buttonDisable: true, mediaLoader: true,
                    mediainputDisable: true,
                }
            })
           
            let error = false;
           
            if (!error) {
                let result = await new Post().uploadFiles(media);
                state.formData.mediaUrls = [...state.formData.mediaUrls, ...result.data];
                setState((pre) => {
                    return {
                        ...pre, ...state, loader: false, buttonDisable: false, mediaLoader: false,
                        mediainputDisable: false,
                    };
                })
            }


        } catch (e) {

        }
    }
   
    const deletemedia = (key, mediaType) => {
        state.formData.mediaUrls= state.formData.mediaUrls.filter((val, index) => {
            return index !== key
        })
       
        setState((pre) => {
            return { ...pre, ...state };
        })
    }
    const onHandler = (event) => {
        try {
            let key = event.target.name;
            let value = event.target.value;
            if (key === "media") {
                uploadMedia(event.target.files, key);
            }
            else if (key === "visibiltyMode") {
                if (state.formData.visibiltyMode === "public") {
                    state.formData.visibiltyMode = "private"
                }
                else {
                    state.formData.visibiltyMode = "public"
                }
                setState((pre) => {
                    return { ...pre, ...state }
                })
            }
            else if (key === "resourceType") {

                state.formData[key] = value;
                state.formData.mediaUrls = [];
                state.formData.mediaGettingUrl = ""
                setState((pre) => {
                    return {
                        ...pre, ...state, mediaGettingUrl: "",
                        mediaUrls: [], imagesUrl: [], videosUrl: [], imageGettingUrl: "",
                        videoGettingUrl: ""
                    }
                })
            }
            else {

                state.formData[key] = value;
                setState((pre) => {
                    return { ...pre, ...state }
                })
            }


        } catch (e) {

        }
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            setState((pre) => {
                return { ...pre, buttonLoader: true, buttonDisable: true }
            })
             await Validate.createResourceItems.validate(state.formData, { abortEarly: false });
             await new Resource().createResourceLibarary(state.formData);
            toasterFunction.success("Resource Libarary has been created");
            
            setState((pre) => {
                return { ...pre, buttonLoader: false, buttonDisable: false }
            })
        } catch (e) {
            let obj = {
                resourceType: [],
                mediaGetting: [],
                visibiltyMode: [],
                title: [],
                description: [],
                mediaUrls: [],
                tags: [],
                categories: [],
                collections: [],
                resources: [],
                other: [],
               
            }

            if (e.errors && e.errors.length > 0) {
                for (let error of e.errors) {
                    obj[Object.keys(error)[0]].push(Object.values(error)[0]);
                }

            }
            else if (e.response) {
                let keys = Object.keys(e.response.data);
                obj[keys[0]] = e.response.data[keys[0]]
            }
            else {
                obj.other.push("Error Occur! Please Contact your Adminstration");
            }



            setState((pre) => {
                return { ...pre, errors: obj, buttonLoader: false, buttonDisable: false }
            })

        }

    }

    if (!state.preLoader) {
        return (<>
            <div className="modal fade addPost_popup" id="addResourcePopup" tabIndex="-1" aria-labelledby="addResourcePopupLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content addPost_content">
                        <div className="modal-header justify-content-center border-0 p-0">
                            <h2 className="modal-title" id="addPostPopupLabel">Add New Resource</h2>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="nav nav-tabs justify-content-center border-0" id="addPost-tab" role="tablist">
                        <button className={`nav-link border-0 ${(state.formData.resourceType==="video")?"active":""}`}  id="nav-video-tab" type='button' name='resourceType' value="video" onClick={onHandler}>
                            Video
                            <svg xmlns="http://www.w3.org/2000/svg" width="98" height="8" viewBox="0 0 108 8">
                                <path id="Fill_1_Copy_2" data-name="Fill 1 Copy 2" d="M90,8a14.9,14.9,0,0,1-9.557-3.248,13.721,13.721,0,0,0-16.888,0A14.9,14.9,0,0,1,54,8a14.9,14.9,0,0,1-9.556-3.248A13.08,13.08,0,0,0,36,1.846a13.079,13.079,0,0,0-8.443,2.906A14.894,14.894,0,0,1,18,8,14.894,14.894,0,0,1,8.443,4.752,13.078,13.078,0,0,0,0,1.846V0A14.9,14.9,0,0,1,9.556,3.248,13.078,13.078,0,0,0,18,6.154a13.079,13.079,0,0,0,8.443-2.906A14.9,14.9,0,0,1,36,0a14.9,14.9,0,0,1,9.556,3.248A13.08,13.08,0,0,0,54,6.154a13.078,13.078,0,0,0,8.443-2.906,15.684,15.684,0,0,1,19.113,0A13.083,13.083,0,0,0,90,6.154c4.194,0,6.258-1.412,8.445-2.906A14.9,14.9,0,0,1,108,0V1.846a13.083,13.083,0,0,0-8.445,2.906A14.9,14.9,0,0,1,90,8" fill="#8b78ef" />
                            </svg>
                        </button>
                      
      <button className={`nav-link border-0 ${(state.formData.resourceType==="pdf")?"active":""}`} id="nav-blog-tab" name='resourceType' value="pdf" onClick={onHandler}>
                            PDF
                            <svg xmlns="http://www.w3.org/2000/svg" width="84" height="8" viewBox="0 0 108 8">
                                <path id="Fill_1_Copy_2" data-name="Fill 1 Copy 2" d="M90,8a14.9,14.9,0,0,1-9.557-3.248,13.721,13.721,0,0,0-16.888,0A14.9,14.9,0,0,1,54,8a14.9,14.9,0,0,1-9.556-3.248A13.08,13.08,0,0,0,36,1.846a13.079,13.079,0,0,0-8.443,2.906A14.894,14.894,0,0,1,18,8,14.894,14.894,0,0,1,8.443,4.752,13.078,13.078,0,0,0,0,1.846V0A14.9,14.9,0,0,1,9.556,3.248,13.078,13.078,0,0,0,18,6.154a13.079,13.079,0,0,0,8.443-2.906A14.9,14.9,0,0,1,36,0a14.9,14.9,0,0,1,9.556,3.248A13.08,13.08,0,0,0,54,6.154a13.078,13.078,0,0,0,8.443-2.906,15.684,15.684,0,0,1,19.113,0A13.083,13.083,0,0,0,90,6.154c4.194,0,6.258-1.412,8.445-2.906A14.9,14.9,0,0,1,108,0V1.846a13.083,13.083,0,0,0-8.445,2.906A14.9,14.9,0,0,1,90,8" fill="#8b78ef" />
                            </svg>
                        </button>
                        <button className={`nav-link border-0 ${(state.formData.resourceType==="word")?"active":""}`} id="nav-blog-tab" name='resourceType' value="word" onClick={onHandler}>
                           Docs/Word
                            <svg xmlns="http://www.w3.org/2000/svg" width="84" height="8" viewBox="0 0 108 8">
                                <path id="Fill_1_Copy_2" data-name="Fill 1 Copy 2" d="M90,8a14.9,14.9,0,0,1-9.557-3.248,13.721,13.721,0,0,0-16.888,0A14.9,14.9,0,0,1,54,8a14.9,14.9,0,0,1-9.556-3.248A13.08,13.08,0,0,0,36,1.846a13.079,13.079,0,0,0-8.443,2.906A14.894,14.894,0,0,1,18,8,14.894,14.894,0,0,1,8.443,4.752,13.078,13.078,0,0,0,0,1.846V0A14.9,14.9,0,0,1,9.556,3.248,13.078,13.078,0,0,0,18,6.154a13.079,13.079,0,0,0,8.443-2.906A14.9,14.9,0,0,1,36,0a14.9,14.9,0,0,1,9.556,3.248A13.08,13.08,0,0,0,54,6.154a13.078,13.078,0,0,0,8.443-2.906,15.684,15.684,0,0,1,19.113,0A13.083,13.083,0,0,0,90,6.154c4.194,0,6.258-1.412,8.445-2.906A14.9,14.9,0,0,1,108,0V1.846a13.083,13.083,0,0,0-8.445,2.906A14.9,14.9,0,0,1,90,8" fill="#8b78ef" />
                            </svg>
                        </button>
                       
                    </div>
                        {/* Content */}
                        <form onSubmit={onSubmit}>
                            <div className="tab-content" id="nav-tabContent">
                                <div>
                                    <div className="uploader_output">
                                        <div className="d-flex flex-wrap">
                                            {(( state.formData.mediaUrls.length < 5 && state.formData.resourceType==="video") && 
                                            <>
                                            <UploaderField disabled={state.mediainputDisable} error={state.imageError} name="media" fun={onHandler} acceptAttr='video/*' /> 
                                             <VideoOutput errors={state.errors} mediaLoader={state.mediaLoader}  type="video" otherType="blog" deleteFun={deletemedia} data={state.formData.mediaUrls} imgSrc='assets/images/output_placeholder.png' />
                                             </>
                                            )}
                                             {(( state.formData.mediaUrls.length < 5 && state.formData.resourceType==="word") && 
                                            <>
                                            <UploaderField disabled={state.mediainputDisable} error={state.imageError} name="media" fun={onHandler} acceptAttr='.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document' /> 
                                            {state.formData.mediaUrls.map((val,index)=>{
                                             return (<>
                                            <div className="col-auto output_img">
                                             <button className='btn p-0 lh-1' type="button" onClick={()=>deletemedia(index,"word")}><i className='fal fa-times'  ></i></button>
                                             <img src="../../../assets/images/docx.png" alt="../../../assets/images/docx.png"/>
                                             </div>
                                                </>)
                                                })}
                                             {((state.mediaLoader) && <> <div class="spinner-border uploader_spinner" style={{width:"3rem",height:"3rem"}} role="status">
                                                <span class="visually-hidden">Loading...</span>
                                                </div></>)}
                                             {state.errors.mediaUrls.map((val,index)=>{
                                             return (<>
                                             <p className='form_error'>{val}</p> 
                                             </>)
                                              })}
                                              </>
                                               )}
                                              {(( state.formData.mediaUrls.length < 5 && state.formData.resourceType==="pdf") && 
                                            <>
                                            <UploaderField disabled={state.mediainputDisable} error={state.imageError} name="media" fun={onHandler} acceptAttr="application/pdf" /> 
                                            {state.formData.mediaUrls.map((val,index)=>{
                                             return (<>
                                             <div className="col-auto output_img">
                                             <button className='btn p-0 lh-1' type="button" onClick={()=>deletemedia(index,"word")}><i className='fal fa-times'  ></i></button>
                                             <img src="../../../assets/images/pdf.png" alt="../../../assets/images/pdf.png" />
                                             </div>
                                             </>)
                                                })}
                                             {((state.mediaLoader) && <> <div class="spinner-border uploader_spinner" style={{width:"3rem",height:"3rem"}} role="status">
                                                <span class="visually-hidden">Loading...</span>
                                                </div></>)}
                                             {state.errors.mediaUrls.map((val,index)=>{
                                             return (<>
                                             <p className='form_error'>{val}</p> 
                                             </>)
                                              })}
                                             </>
                                            )}
                                           
                                            </div>
                                    </div>
                                    <PostFields errors={state.errors} data={{ state: state, onHandler: onHandler, parentStateFun: setState }} />
                                </div>

                                <div className="post_now_btn mt-5">
                                    <button type='submit' className='btn site-btn' style={{ width: '100%', maxWidth: '400px' }} disabled={state.buttonDisable}><span>I Would Like to Post Now  {(state.buttonLoader) ? <div className="spinner-border" style={{ width: "1rem", height: "1rem" }}></div> : ""}</span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>);
    }
    else {
        return (<>
            <Loader />
        </>)

    }

}

export default AddResource;