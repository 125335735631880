import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import Categorycard from './CategoryCard';
import { useAuthContext } from '../../context';
import { CollectionDashBoard } from '../popup/collectionDashBoard';
import { Modal } from '../../helpers';
import { CategoryPostSkeleton } from '../Skeleton';
import { Post } from '../../sdk';
import { toasterFunction } from '../DefaultComponents';
import { dispatch as parentDispatch } from '../../redux';
import { getCategoriesPostThunk } from '../../redux/Thunk';
const SearchPost = () => {
    const userAuth = useAuthContext();
    const [state, setState] = useState({ postId: "", posts: [], loader: true });
    let dispatch = parentDispatch();
    const backToHome = () => {
        dispatch(getCategoriesPostThunk())
    }
    const getData = async (keyword) => {
        setState((pre) => {
            return { ...pre, loader: true }
        })
        try {
            let result = await new Post().searchInSite({ keyword })

            if (result.length) {

                Modal.hideTab(".modal");
                window.$("input[name=search]").val("")
                setState((pre) => {
                    return { ...pre, posts: result, loader: false }
                })
            }
            else {
                toasterFunction.error("no data found against your keyword");
                backToHome();
            }
        } catch (e) {
            console.log(e)
        }
    }
    let searchKeyword = window.localStorage.getItem("search");
    useEffect(() => {

        if (searchKeyword) {
            getData(window.localStorage.getItem("search"));
        }
        else {
            backToHome();
        }


    }, [searchKeyword])
    const onClickTarget = (e, postId, target) => {
        Modal.hideTab(".modal");
        setState((pre) => {
            return { ...pre, postId: postId }
        })

        Modal.showTab(target);
    }
    return (<>
        {state.loader &&
            <CategoryPostSkeleton count={4} />
        }
        {!state.loader &&
            <>
                <div className="category-container" style={{ padding: "15px" }}>
                    <div className="container">

                        <div className="category-cards-container">
                            <OwlCarousel className='category-cards-slider owl-theme'
                                loop={false}
                                margin={15}
                                nav={false}
                                dots={false}
                                responsive={{
                                    0: {
                                        items: 1,
                                        stagePadding: 20
                                    },
                                    768: {
                                        items: 2,
                                        stagePadding: 30
                                    },
                                    992: {
                                        items: 3,
                                        stagePadding: 30
                                    },
                                    1200: {
                                        margin: 29,
                                        items: 4,
                                        stagePadding: 0,
                                        touchDrag: false,
                                        mouseDrag: false
                                    }
                                }}>


                                <Categorycard
                                    postDetails={state.posts}
                                    error="There is no post against this"
                                    onClickTarget={onClickTarget}
                                />


                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </>
        }
        {(userAuth.isAuthenticated) && <CollectionDashBoard postId={state.postId} />}

    </>);
}

export default SearchPost;
