import React,{useState} from 'react';
import { useRef } from 'react';
import { useAuthContext } from '../../context';
import { Collection, Resource } from '../../sdk';
import { toasterFunction } from '../DefaultComponents';
import { OwnUserUpdateByKey } from '../../redux/Actions';
import { dispatch as parentDispatch } from '../../redux';
const ProfileCollectionCard = (props) => {
    let followCount=props.followers;
    const dispatch=parentDispatch();
    const [state,setState]=useState(props.follow);
    const userAuth=useAuthContext();
     const toggleResource=async(id)=>{
        try{
        ref.current.disabled=true;
         let result=await new Resource().toggleFollow(id);
         toasterFunction.success(result.message);
         if(result.follow){
            followCount=followCount+1
         }
         else{
            followCount=followCount-1
         }
         setState(result.follow);
         ref.current.disabled=false;
        }catch(e){
         ref.current.disabled=false;
         toasterFunction.error("something get wrong");
        }
     }
     const deleteCollection=async (id)=>{
        try{
            if(window.confirm("are you sure to delete this collection")){
                await new Collection().deleteCollection(id);
                dispatch(OwnUserUpdateByKey("collections",{_id:id},"remove"));
                toasterFunction.success("collection has been delete");
            }
         
        }catch(e){
toasterFunction.error("something went wrong")
        }
     }
     const ref=useRef("");
    if (props.detailPage === true) {
        return (<>
            <div className={`resource_parent ${props.onProfile ? 'onProfile' : ''}`}>
                <div className="row justify-content-between">
                    <div className={`${props.onProfile ? 'col-lg-2' : 'col-lg-4'}`}>
                    <div className="img">
                        <img src={props.imgSrc} alt="Collection Feature Img" className='collection_img' />
                        </div>
                    </div>
                    <div className={`${props.onProfile ? 'col-lg-10' : 'col-lg-8'}`}>
                        <div className="txt">
                            <h2>{props.collectionName} {props.premium ? <img src="/assets/images/premium_icon.svg" alt="Premium Icon" style={{ maxWidth: 89, marginLeft: 15 }} /> : null}</h2>
                            <p>{props.collectionDescription}</p>
                            <div className={`d-flex align-items-center ${props.onProfile ? 'justify-content-end' : 'justify-content-between'}`}>
                                {props.onProfile ? <p className='follow'>Followers : <span>{followCount}</span></p> : 
                                <>
                                <p className='createBy'>Created By <span className='d-block'>{props.postAuthor}</span></p>
                                  <div className="cta_btns">
                                    <button className='btn site-btn btn-follow' ref={ref} onClick={()=>toggleResource(props.id)}><span>{state ? "Following":"Follow"}</span></button>
                                    {props.premium ? <button className='btn site-btn'><span>Buy Now ${props.postPrice}</span></button> : null }
                                </div>
                                </>
                                }
                             
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>);
    } else {
        return (<>
            <div className="profile_collection_card">
                <a className='d-block' style={{ cursor: 'pointer' }} onClick={() => props.linkUrl(props.id, props.lock)}>
                    <div className="img position-relative">
                        <img src={props.imgSrc} alt="Collection Feature Img" className='collection_img' style={{ maxHeight: "184px" }} />
                        <div className="controls">
                            {props.type==="collection" && <>
                           
                            <button className="btn p-0"><img src="assets/images/edit.svg" alt="Edit Icon" onClick={()=>props.onUpdate(props.id)}/></button>
                            </>}
                            
                        </div>
                    </div>
                    <h3 className='collection_names d-flex justify-content-between align-items-center'>{props.collectionName}  {props.type==="collection" && <> <button className="btn p-0" onClick={()=>deleteCollection(props.id)}><img src="assets/images/delete.svg" alt="Delete Icon" /></button></>}    {props.lock && <img src="/assets/images/premium_icon.svg" alt="Premium Icon" style={{ maxWidth: 89 }} />}</h3>
                </a>
            </div>
        </>);
    }
}

export default ProfileCollectionCard;