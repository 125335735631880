import { useEffect, useState } from "react"
import { toasterFunction } from "../DefaultComponents"
import { Auth, User } from "../../sdk"
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../customHooks";
import { useAuthContext } from "../../context";
import { Modal } from "../../helpers";

export const ConfirmDeleteAcc = () => {
    let navigate = useNavigate();
    const[,logoutUser]=useAuthentication();
    const [state, setState] = useState({
        reasons : '',
        password : ''
    });
    const userAuth=useAuthContext();
    
    const handleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        setState((pre) => {
            return {...pre, [name] : value};
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let result = await new User().deleteUser(state);
            // console.log(result);
            // if (result.status === 200) {
                toasterFunction.success("Your Account has been Deleted");
                await new Auth().logout();
                Modal.hideTab("#deleteAcc")
                logoutUser();
            // }
        } catch (e) {
           
           toasterFunction.error(e.response.data[0])
        }
        
    }
    return (<>
        <div className="modal fade success_popup" id="deleteAcc" tabIndex="-1" aria-labelledby="deleteAccLabel" aria-hidden="true" >
            <div className="modal-dialog">
                <div className="modal-content">
                    <form onSubmit={handleSubmit}>
                        <div className="success_content">
                            <div className="modal-header justify-content-center border-0">
                                <h2 className="modal-title" id="deleteAccLabel">Delete Account Confirmation</h2>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body py-4 text-center">
                                <p className="mt-0">Are you sure you want to delete your Account?</p>
                                <div class="post-field-website mt-3 text-start px-3">
                                    <label htmlhtmlfor="reasonDeleteAcc">Reason</label>
                                    <textarea name="reasons" id="reasonDeleteAcc" rows="5" class="form-control" value={state.reasons} onChange={handleChange}></textarea>
                                </div>
                                {userAuth.user.ispassword && <><div class="post-field-website mb-0 text-start px-3">
                                    <label htmlhtmlfor="password">Enter Your Password</label>
                                    <input type="password" id="password" name="password" class="form-control" placeholder="******" value={state.password} onChange={handleChange} />
                                </div></>}
                                
                            </div>
                            <div className="modal-footer justify-content-between border-0">
                                <div className="row justify-content-between w-100">
                                    <div className="col-6">
                                        <button type="submit" className="btn site-btn delete_btn w-100"><span>Yes</span></button>
                                    </div>
                                    <div className="col-6">
                                        <button type="button" className="btn site-btn w-100" data-bs-dismiss="modal"><span>No</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>)
}