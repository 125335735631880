
export const TermsAndCondition=(props)=>{
    return (<>
    <div className="modal fade terms_popup" id="termsPopup" tabIndex="-1" aria-labelledby="termsPopupLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <form>
                        <div className="terms_content">
                            <div className="modal-header border-0">
                                <h2 className="modal-title" id="termsPopupLabel">Terms of Services
                                </h2>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <p>The Terms of Services was last updated on the 23rd April, 2020 which is the date these Terms of Services became effective.
                                </p>
                                <p>The Terms of Service, with our Privacy Policy, form the full and exclusive legal agreement between you and Your Growth Stop and has the same effect as if you had physically signed an agreement on physical paper.
                                </p>
                                <p>The Terms of Services includes all terms listed in this document.
                                </p>
                                <p>The Your Growth Stop Website/Network and Mobile Application are owned and operated by Your Growth Stop Limited (“Your Growth Stop”, “us”, “we” or “our”). By accessing and using the services which are available through the Your Growth Stop Website/Network
                                    or Mobile applications, you agree to these Terms of Services and the Your Growth Stop Privacy Policy which you can be found via the following link ….</p>
                                <p className="mb-0">The Terms of Services (‘‘Terms’’) governs our rights and your rights with regards to any actions by you or other users in connection with the Your Growth Stop services. We Recommend that you read these Terms in detail;
                                </p>
                                <p>The Terms and Services are governed by the New Zealand Law.
                                </p>
                                <h3>Terms of Services – (Contacting US)</h3>
                                <ol>
                                    <li>We are always open to feedback, suggestions and opinions. All feedback, suggestions and opinions that you do submit us will automatically become our property, legal owned by us and therefore we may use and publish any feedback,
                                        suggestions and opinions at our discretion without any remuneration or attribution to you.</li>
                                    <li>When contacting us for any reason other than making a claim which is related to the Terms of Services, we cannot guarantee a reply in any specific time frame. Terms of Services – (General)</li>
                                </ol>
                                <h3>Terms of Services – (General)</h3>
                                <ol>
                                    <li>We are always open to feedback, suggestions and opinions. All feedback, suggestions and opinions that you do submit us will automatically become our property, legal owned by us and therefore we may use and publish any feedback,
                                        suggestions and opinions at our discretion without any remuneration or attribution to you.</li>
                                    <li>When contacting us for any reason other than making a claim which is related to the Terms of Services, we cannot guarantee a reply in any specific time frame. Terms of Services – (General)</li>
                                </ol>
                                <p>The Terms of Services was last updated on the 23rd April, 2020 which is the date these Terms of Services became effective.
                                </p>
                                <p>The Terms of Service, with our Privacy Policy, form the full and exclusive legal agreement between you and Your Growth Stop and has the same effect as if you had physically signed an agreement on physical paper.
                                </p>
                                <p>The Terms of Services includes all terms listed in this document.
                                </p>
                                <p>The Your Growth Stop Website/Network and Mobile Application are owned and operated by Your Growth Stop Limited (“Your Growth Stop”, “us”, “we” or “our”). By accessing and using the services which are available through the Your Growth Stop Website/Network
                                    or Mobile applications, you agree to these Terms of Services and the Your Growth Stop Privacy Policy which you can be found via the following link ….</p>
                                <p>The Terms of Services (‘‘Terms’’) governs our rights and your rights with regards to any actions by you or other users in connection with the Your Growth Stop services. We Recommend that you read these Terms in detail;
                                </p>
                                <p>The Terms and Services are governed by the New Zealand Law.
                                </p>
                                <h3>Terms of Services – (Contacting US)</h3>
                                <ol>
                                    <li>We are always open to feedback, suggestions and opinions. All feedback, suggestions and opinions that you do submit us will automatically become our property, legal owned by us and therefore we may use and publish any feedback,
                                        suggestions and opinions at our discretion without any remuneration or attribution to you.</li>
                                    <li>When contacting us for any reason other than making a claim which is related to the Terms of Services, we cannot guarantee a reply in any specific time frame. Terms of Services – (General)</li>
                                </ol>
                                <h3>Terms of Services – (General)</h3>
                                <ol>
                                    <li>We are always open to feedback, suggestions and opinions. All feedback, suggestions and opinions that you do submit us will automatically become our property, legal owned by us and therefore we may use and publish any feedback,
                                        suggestions and opinions at our discretion without any remuneration or attribution to you.</li>
                                    <li>When contacting us for any reason other than making a claim which is related to the Terms of Services, we cannot guarantee a reply in any specific time frame. Terms of Services – (General)</li>
                                </ol>
                            </div>
                            <div className="modal-footer justify-content-between align-items-center">
                                <button type="button" className="btn site-btn accept" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#successPopup" onClick={(event)=>props.agreeTerms(event,true)}><span>I agree</span></button>
                                <button type="button" className="btn decline" data-bs-dismiss="modal"><span>x</span>
                                    Decline</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>)
}