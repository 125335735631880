import React, { useState } from 'react';
import { useAuthContext } from '../../context';
import { Modal, Validate } from '../../helpers';
import { Resource, Collection } from '../../sdk';
import { toasterFunction } from '../DefaultComponents';
import { OwnUserUpdateByKey } from '../../redux/Actions';
import { dispatch as parentDispatch } from '../../redux';

const Myprofilecta = (props) => {
    const userAuth = useAuthContext();
    const dispatch=parentDispatch();
    const [state, setState] = useState({
        collectionFormHandling: {
            formData: {
                name: "",
                description: "",
                img_url: {},
                img_urlShow: "assets/images/profile_silhotte.svg",
            },
            errors: {
                name: [],
                description: [],
                img_url: [],
                other: []
            }
        },
        resourceFormHandling: {
            formData: {
                name: "",
                description: "",
                img_url: {},
                img_urlShow: "assets/images/profile_silhotte.svg",
                premium: 0,
                subscriptionType:"monthly",
                ammount:""


            },
            errors: {
                name: [],
                description: [],
                img_url: [],
                premium: [],
                subscriptionType:[],
                ammount:[],
                other: [],

            }
        },
        buttonLoader: false,
        buttonDisable: false
    })
    const onHandler = (event) => {
        let key = event.target.name;
        let value = event.target.value;
        if (key === "img_url") {
            if (event.target.files[0].size > 5 * (1024 * 1024)) {
               
                alert("image should be maximum 5 mb")
                return false;
            }
            else {
                state.collectionFormHandling.formData.img_url = event.target.files[0];
                state.collectionFormHandling.formData.img_urlShow = URL.createObjectURL(event.target.files[0]);
                setState((pre) => {
                    return { ...pre, ...state };
                })

            }
        }
        else {
            state.collectionFormHandling.formData[key] = value;
            setState((pre) => {
                return { ...pre, ...state };
            })
        }
    }
    const onResourceHandler = (event) => {
        let key = event.target.name;
        let value = event.target.value;
        if (key === "img_url") {
            if (event.target.files[0].size > 5 * (1024 * 1024)) {
                // let errors = {
                //     img_url: ["File Size not greater than 5 mb"],
                //     name: [],
                //     description: [],
                //     premium: [],
                //     subscriptionType:[],
                //     ammount:[],
                //     other: [],
                //     other: []
                // };
                // state.resourceFormHandling.errors = errors;
                // setState((pre) => {
                //     return { ...pre, ...state };
                // })
                alert("image should be maximum 5 mb")
                return false;
            }
            else {
                state.resourceFormHandling.formData.img_url = event.target.files[0];
                state.resourceFormHandling.formData.img_urlShow = URL.createObjectURL(event.target.files[0]);
                setState((pre) => {
                    return { ...pre, ...state };
                })

            }
        } else if (key === "premium") {
            state.resourceFormHandling.formData.premium = (state.resourceFormHandling.formData.premium === 1) ? 0 : 1;
            console.log(state.resourceFormHandling)
            setState((pre) => {
                return { ...pre, ...state };
            })
        }
        else {
            state.resourceFormHandling.formData[key] = value;
            setState((pre) => {
                return { ...pre, ...state };
            })
        }
    }
    const onModalSubmit = async (e) => {
        e.preventDefault();
        try {
            setState((pre) => {
                return { ...pre, buttonLoader: true, buttonDisable: true }
            })
            let valid = await Validate.createCollection.validate(state.collectionFormHandling.formData, { abortEarly: false });
            let result = await new Collection().createCollection(state.collectionFormHandling.formData);
            dispatch(OwnUserUpdateByKey("collections",result,"push"));
            toasterFunction.success("Collection created successfully");
            Modal.hideTab("#createCollection");
            if(localStorage.getItem("modal")){
                Modal.showTab(localStorage.getItem("modal"))
            }
            
            setState((pre) => {
                return { ...pre, buttonLoader: false, buttonDisable: false }
            })
        } catch (e) {
            console.log(e)
            let obj = {
                name: [],
                description: [],
                img_url: [],
                other: []
            }
            console.log(e.errors)
            if (e.errors && e.errors.length > 0) {
                for (let error of e.errors) {
                    if (typeof (error) === "string") {
                        obj["password"].push(error);

                    }
                    else {
                        obj[Object.keys(error)[0]].push(Object.values(error)[0]);

                    }

                }

            }
            else if (e.response) {
                let keys = Object.keys(e.response.data);
                obj[keys[0]] = e.response.data[keys[0]]
            }
            else {
                obj.other.push("Error Occur! Please Contact your Adminstration");
            }
            state.collectionFormHandling.errors = obj;
            setState((pre) => {
                return { ...pre, ...state, buttonDisabled: false, buttonLoader: false };
            })

        }

    }
    const onResourceModalSubmit = async (e) => {
        e.preventDefault();
        try {
            setState((pre) => {
                return { ...pre, buttonLoader: true, buttonDisable: true }
            })
            let valid = await Validate.createCollection.validate(state.resourceFormHandling.formData, { abortEarly: false });
            let result = await new Resource().createCollection(valid);
            dispatch(OwnUserUpdateByKey("resources",result,"push"));
            toasterFunction.success("Resource has been Created");
            Modal.hideTab("#createResource");
            Modal.showTab("#addResourcePopup")
            setState((pre) => {
                return { ...pre, buttonLoader: false, buttonDisable: false }
            })
        } catch (e) {
            console.log(e)
            let obj = {
                name: [],
                description: [],
                img_url: [],
                other: []
            }
            console.log(e.errors)
            if (e.errors && e.errors.length > 0) {
                for (let error of e.errors) {
                    if (typeof (error) === "string") {
                        obj["password"].push(error);

                    }
                    else {
                        obj[Object.keys(error)[0]].push(Object.values(error)[0]);

                    }

                }

            }
            else if (e.response) {
                let keys = Object.keys(e.response.data);
                obj[keys[0]] = e.response.data[keys[0]]
            }
            else {
                obj.other.push("Error Occur! Please Contact your Adminstration");
            }
            state.resourceFormHandling.errors = obj;
            setState((pre) => {
                return { ...pre, ...state, buttonDisabled: false, buttonLoader: false };
            })

        }

    }
    return (<>



        <div class="modal fade collect-resource" id="createCollection" tabindex="-1" aria-labelledby="createCollectionLabel" aria-hidden="true">
            <form onSubmit={onModalSubmit}>
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header justify-content-center border-0">
                            <h5 class="modal-title" id="createCollectionLabel">Create New Collection</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">

                            <div className="profile_img_uploader mt-4">
                                <div className="uploader d-inline-block position-relative  mt-4">
                                    <img src={state.collectionFormHandling.formData.img_urlShow} alt="Profile Img" className='profile' />
                                    <button className='btn p-0 shadow-none'>
                                        <input className='uploader_input' type="file" name="img_url" id="ProfileImgUploader" accept='image/*' onChange={onHandler} />
                                        <img src="assets/images/uploader_icon.svg" alt="Img Uploadern Icon" />
                                       
                                    </button>
                                  
                                </div>
                                {state.collectionFormHandling.errors.img_url.map((val, index) => {
                                            return (<>
                                                <p className='form_error'>{val}</p>
                                            </>)
                                        })}

                            </div>

                            <div class="post-field-title">
                                <label htmlFor="collectionTitle">Collection Name</label>
                                <input type="text" name="name" id="collectionTitle" value={state.collectionFormHandling.formData.name} onChange={onHandler} className="form-control" placeholder="Any Text Here" required />
                                {state.collectionFormHandling.errors.name.map((val, index) => {
                                    return (<>
                                        <p className='form_error'>{val}</p>
                                    </>)
                                })}
                            </div>

                            <div class="post-field-title mb-4">
                                <label for="collectionDescription">Collection Description</label>
                                <textarea name="description" id="collectionDescription" class="form-control" rows="9" value={state.collectionFormHandling.formData.description} onChange={onHandler} placeholder="Dorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua pt enim ad minim" spellCheck="false" required></textarea>
                                {state.collectionFormHandling.errors.description.map((val, index) => {
                                    return (<>
                                        <p className='form_error'>{val}</p>
                                    </>)
                                })}
                            </div>

                        </div>
                        <div class="modal-footer border-0 justify-content-between">
                            <button type="button" class="btn close_btn shadow-none" data-bs-dismiss="modal" aria-label="Close"><i className='far fa-arrow-left'></i> Back to Collections</button>
                            <button className='btn site-btn' style={{ maxWidth: '300px', width: '100%', padding: '12px 12px' }} type="submit" disabled={state.buttonDisable}>
                                <span>Create Collection{(state.buttonLoader) ? <div className="spinner-border" style={{ width: "1rem", height: "1rem" }}></div> : ""}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal fade collect-resource" id="createResource" tabindex="-1" aria-labelledby="createCollectionLabel" aria-hidden="true">
            <form onSubmit={onResourceModalSubmit}>
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header justify-content-center border-0">
                            <h5 class="modal-title" id="createCollectionLabel">Create Resource Library</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            {/* Profile Img */}
                            <div className="profile_img_uploader mt-4">
                                <div className="uploader d-inline-block position-relative  mt-4">
                                    <img src={state.resourceFormHandling.formData.img_urlShow} alt="Profile Img" className='profile' />
                                    <button className='btn p-0 shadow-none'>
                                        <input className='uploader_input' type="file" name="img_url" id="ProfileImgUploader" accept='image/*' onChange={onResourceHandler} required />
                                        <img src="assets/images/uploader_icon.svg" alt="Img Uploadern Icon" />
                                        
                                    </button>

                                </div>
                                    {state.resourceFormHandling.errors.img_url.map((val, index) => {
                                            return (<>
                                                <p className='form_error'>{val}</p>
                                            </>)
                                        })}

                            </div>
                            {/* Post Field Name */}
                            <div class="post-field-title">
                                <label for="collectionTitle">Name</label>
                                <input type="text" name="name" id="collectionTitle" value={state.resourceFormHandling.formData.name} onChange={onResourceHandler} class="form-control" placeholder="Any Text Here" required />
                                {state.resourceFormHandling.errors.name.map((val, index) => {
                                    return (<>
                                        <p className='form_error'>{val}</p>
                                    </>)
                                })}
                            </div>
                            {/* Post Field Description */}
                            <div class="post-field-title mb-4">
                                <label for="collectionDescription">Description</label>
                                <textarea name="description" id="collectionDescription" class="form-control" rows="9" value={state.resourceFormHandling.formData.description} onChange={onResourceHandler} placeholder="Dorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua pt enim ad minim" spellCheck="false" required></textarea>
                                {state.resourceFormHandling.errors.description.map((val, index) => {
                                    return (<>
                                        <p className='form_error'>{val}</p>
                                    </>)
                                })}
                            </div>
                            {/* Post Subscription */}
                            <div className="post_sub row">
                                <div className="col-auto">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value={state.resourceFormHandling.formData.premium} id="premiumOffer" name='premium' onChange={onResourceHandler} />
                                        <label class="form-check-label" for="premiumOffer">
                                            Premium
                                        </label>
                                    </div>
                                </div>
                                {state.resourceFormHandling.formData.premium === 1 ?
                                    <div className="col-auto">
                                        <h3>Subscription Type:</h3>
                                        <div class="form-check">
                                            <label class="form-check-label" for="monthlySub">
                                                Monthly
                                            </label>
                                            <input class="form-check-input" type="radio" name="subscriptionType" id="monthlySub" value="monthly" onChange={onResourceHandler} checked={state.resourceFormHandling.formData.subscriptionType==="monthly"}/>
                                        </div>
                                        <div class="form-check">
                                            <label class="form-check-label" for="yearlySub">
                                                Yearly
                                            </label>
                                            <input class="form-check-input" type="radio" name="subscriptionType" id="yearlySub" value="yearly" onChange={onResourceHandler} checked={state.resourceFormHandling.formData.subscriptionType==="yearly"}/>
                                        </div>
                                        <div class="form-check">
                                            <label class="form-check-label" for="oneOffPay">
                                                One off Payment
                                            </label>
                                            <input class="form-check-input" type="radio" name="subscriptionType" id="oneOffPay" value="onetime" onChange={onResourceHandler} checked={state.resourceFormHandling.formData.subscriptionType==="onetime"}/>
                                        </div>
                                        <div class="d-flex align-items-center mt-4">
                                            <label for="subsAmount" class="form-label mb-0 me-2">Amount:</label>
                                            <input type="text" class="form-control" id="subsAmount" placeholder="Enter Amount" name="ammount"value={state.resourceFormHandling.formData.ammount} onChange={onResourceHandler} required/>
                                        </div>
                                    </div> : null
                                }
                            </div>
                        </div>
                        <div class="modal-footer border-0 justify-content-between">
                            {/* <button type="button" class="btn close_btn shadow-none" data-bs-dismiss="modal" aria-label="Close"><i className='far fa-arrow-left'></i> Back to Collections</button> */}
                            <button className='btn site-btn' style={{ maxWidth: '300px', width: '100%', padding: '12px 12px' }} type="submit" disabled={state.buttonDisable}>
                                <span>Create Resource Library{(state.buttonLoader) ? <div className="spinner-border" style={{ width: "1rem", height: "1rem" }}></div> : ""}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </>);
}

export default Myprofilecta;